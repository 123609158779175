import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Product } from 'common/interfaces/product';

@Component({
  selector: 'app-add-products-modal',
  templateUrl: './add-products-modal.component.html',
  styleUrls: ['./add-products-modal.component.scss'],
})
export class AddProductsModalComponent implements OnInit {

  constructor(private modalController: ModalController) { }
  //TODO: Replace with proper products
  // The quantity is required to know how many item were added
  @Input() products: (Partial<Product>&{enabled?: boolean, quantity?: number})[];

  ngOnInit() {
    this.products.forEach(product => {
      product.quantity = null;
    });
  }

  async onSubmit() {  
    // Remove any products with quantity less than or equal to 0
    this.products = this.products.filter(product => product.quantity && product.quantity > 0);
    await this.modalController.dismiss({products: this.products});
  }

  async onCancel() {
    await this.modalController.dismiss();
  }

}
