import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PriceList } from 'app/shared/common/interfaces/priceList';
import { RecordPageRequest } from 'app/shared/common/interfaces/search';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PriceListService {

  constructor(private http: HttpClient) { }

  getPriceLists(companyId: string, recordPageRequest: RecordPageRequest): Observable<{priceList:PriceList[]; total: number}> {    
    return this.http.post<{priceList:PriceList[]; total: number}>('price-list/list/', {companyId, recordPageRequest} );
  }

  getPriceList(id: string): Observable<PriceList> {
    return this.http.get<PriceList>(`price-list/${id}`);
  }

  createPriceList(priceList: PriceList) {
    return this.http.post('price-list', {priceList}, {responseType: 'text'});
  }

  updatePriceList(priceList: PriceList) {
    return this.http.put('price-list', {priceList}, {responseType: 'text'});
  }

  deletePriceLists(priceListIds: string[]) {
    return this.http.delete('price-list', { body: { priceListIds }, responseType: 'text' });
  }
}
