import { ActionPrivileges } from './privileges';
import { Company } from './company';
import { DocumentTypes } from './document';
import { Order } from './order';
import { User } from './user';
import { DeliveryLocation } from './deliveryLocation';
import { Product } from './product';
import { PriceList } from './priceList';
import { Invoice } from './invoice';

export interface Metadata {
  createdBy: string;
  creationDate: Date;
  updatedBy: string;
  updateDate: Date;
}

export interface Address {
  addressLine1: string;
  addressLine2?: string;
  addressLine3?: string;
  addressLine4?: string;

  postcode: string;
  countryCode: CountryCode;
}

export enum CountryCode {
  UK,
  HK,
  GER
}

export enum Currency {
  GBP,
  USD,
  HKD
  // ...
}

export interface VatRate {
  rate: number;
  code: VatCode;
  country: Country;
}

export enum VatCode {
  S,
  Z,
  R
}

export enum Country { // Should this have a new interface that combines country name and code?
  UnitedKingdom,
  HongKong,
  Germany
  // ...
}

export interface Settings {
  dateFormat: string;
  timezone: Company['timezone'];
  language: Language;
  // ...
}

export enum Language {
  English,
  Chinese,
  German,
  // ...
}

export interface AlertMatrix {
  email: string; // not User['email'] because it could be the email address not from a user
  actions: DocumentTypes<ActionPrivileges>;
}
//we acknowledge the similarity to RecordType below but this one is subject to change so no need to worry.
export interface RecordTypes<T> extends DocumentTypes<T> { 
  users: T;
  products: T;
  priceLists: T;
  priceListProducts: T;
  // productShortLists: T;
}

export interface ExportDocument {
  csv: string;
}

export enum ChildView {
  ORDER,
  Invoice,
  ASN
}
export type RecordType = Order | User | DeliveryLocation | Product | PriceList | Invoice | Company;

export type RecordTypeName = 'Order' | 'User' | 'DeliveryLocation' | 'Product' | 'PriceList' | 'Invoice' | 'Company';
