import { Component,  Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ValidatorService } from 'app/core/services/validator.service';
import { LineItem } from 'app/shared/common/interfaces/lineItem';

@Component({
  selector: 'app-line-item-modal',
  templateUrl: './line-item-modal.component.html',
  styleUrls: ['./line-item-modal.component.scss'],
})
export class LineItemModalComponent implements OnInit {

  
  @Input() lineItem: Partial<LineItem>;
  @Input() editMode: boolean = true;
  lineItemForm: FormGroup;
 
  ui:any;

  constructor(private modalController: ModalController, private validatorService: ValidatorService) { }

  ngOnInit() {
    this.lineItemForm = new FormGroup({
      lineNumber: new FormControl(),
      barcodeNumber: new FormControl(),
      supplierItemCode: new FormControl(),
      quantity: new FormControl(),
      caseSize: new FormControl(),
      description: new FormControl(),
      vatCode: new FormControl(),
      price: new FormControl()
    });

    this.ui = {
      //_id
      //associatedWith
      //actieRecord
      uiData:[
        {
          name: 'line-item-modal' ,
          panel: {
            title: 'LINEITEMHEADER',
            description: 'LINEITEMDESCRIPTION',
            icon: 'document',
          },
          fieldGroups: [
            {
              name:'lineItemsGroup',
              label:'LINEITEMSGROUP',
              fields:[
                {
                  name: 'lineNumber',
                  label: 'LINENUMBER',
                  type: 'input',
                  text: this.lineItem.lineNumber.toString(),
                  readonly: true,
                  bigScreenSize :12,
                  mediumScreenSize : 12,
                  smallScreenSize : 12
                },
                {
                  name: 'barcodeNumber',
                  label: 'BARCODE',
                  type: 'input',
                  text: this.lineItem.barcodeNumber,
                  bigScreenSize :12,
                  mediumScreenSize : 12,
                  smallScreenSize : 12
                },
                {
                  name: 'supplierItemCode',
                  label: 'SUPPLIERITEMCODE',
                  type: 'input',
                  text: this.lineItem.supplierItemCode,
                  bigScreenSize :12,
                  mediumScreenSize : 12,
                  smallScreenSize : 12
                },
                {
                  name: 'quantity',
                  label: 'QUANTITY',
                  type: 'input',
                  text: this.lineItem.quantity.toString(),
                  bigScreenSize :12,
                  mediumScreenSize : 12,
                  smallScreenSize : 12,
                  validators:[                    
                    {
                      name:'required',
                      errorType:'required',
                      message:'QUANTITYREQUIRED'
                    }
                  ]
                },
                {
                  name: 'caseSize',
                  label: 'CASESIZE',
                  type: 'input',
                  text: this.lineItem.caseSize.toString(),
                  bigScreenSize :12,
                  mediumScreenSize : 12,
                  smallScreenSize : 12,
                  validators:[                    
                    {
                      name:'required',
                      errorType:'required',
                      message:'CASESIZEREQUIRED'
                    }
                  ]
                },
                {
                  name: 'vatCode',
                  label: 'VATCODE',
                  type: 'input',
                  text: this.lineItem.vatCode + '',
                  bigScreenSize :12,
                  mediumScreenSize : 12,
                  smallScreenSize : 12
                },
                {
                  name: 'price',
                  label: 'PRICE',
                  type: 'input',
                  text: this.lineItem.price + '',
                  bigScreenSize :12,
                  mediumScreenSize : 12,
                  smallScreenSize : 12,
                  validators:[                    
                    {
                      name:'required',
                      errorType:'required',
                      message:'PRICEREQUIRED'
                    }
                  ]
                },
                {
                  name: 'description',
                  label: 'DESCRIPTION',
                  type: 'input',
                  text: this.lineItem.description,
                  bigScreenSize :12,
                  mediumScreenSize : 12,
                  smallScreenSize : 12
                },
              ]
            }
          ]
      }],
      //formValidators
    }

    this.lineItemForm.patchValue({
      lineNumber: this.lineItem.lineNumber,
      barcodeNumber: this.lineItem.barcodeNumber,
      supplierItemCode: this.lineItem.supplierItemCode,
      quantity: this.lineItem.quantity,
      vatCode: this.lineItem.vatCode,
      caseSize: this.lineItem.caseSize,
      description: this.lineItem.description,
      price: this.lineItem?.price
    });

    this.lineItemForm = this.validatorService.setValidatorFromUI(this.lineItemForm, this.ui );

  }

  async onCancel() {
    await this.modalController.dismiss();
  }

  async onSave() {
    if(!this.lineItemForm.valid){
      //Now only stop us from saving invalid line item without any alert. 
      //TODO: add alert function here later on. 
      return;
    }
    await this.modalController.dismiss({...this.lineItem, ...this.lineItemForm.value});
  }

}
