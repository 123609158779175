/* tslint:disable:no-bitwise */
import { BinaryActions } from '../actions';

// when importing this module use 'import * as PrivilegeHelpers from 'app/shared/common/modules/privilege-helpers';'

/**
 * Produces a BinaryActions number containing all actions except the specified ones
 *
 * @param binaryActions BinaryActions number containing actions to exclude from all actions
 *
 * @returns BinaryActions number, containing all actions, with the specified actions removed
 */
export function allExcept(binaryActions: BinaryActions): BinaryActions {
  return BinaryActions.all & ~binaryActions;
}

/**
 * Includes specified actions to a BinaryActions number
 *
 * Possible to add multiple actions
 *
 * @param binaryActions BinaryActions number to add to
 * @param toRemove BinaryActions number, containing the actions that should be added
 *
 * @returns BinaryActions number with the specified actions added
 */
export function includeIn(binaryActions: BinaryActions, toInclude: BinaryActions): BinaryActions {
  return binaryActions |= toInclude;
}

/**
 * Removes specified actions from a BinaryActions number
 *
 * Possible to remove multiple actions
 *
 * @param binaryActions BinaryActions number to remove from
 * @param toRemove BinaryActions number, containing the actions that should be removed
 *
 * @returns BinaryActions number with the specified actions removed
 */
export function removeFrom(binaryActions: BinaryActions, toRemove: BinaryActions): BinaryActions {
  return binaryActions &= ~toRemove;
}

/**
 * Combines any number of BinaryActions numbers together using the and logical operator
 *
 * Only privileges that are true on all masks given will be true on the output BinaryActions number
 *
 * @param masks Rest parameters. This could be any number of BinaryActions numbers
 *
 * @returns BinaryActions number containing the masked value
 */
export function combineMasks(...masks: BinaryActions[]): BinaryActions {
  return masks.reduce((accumulator, mask) => {
    return accumulator & mask;
  });
}

/**
 * Checks whether a specific action is contained within a BinaryActions number
 *
 * @param binaryActions BinaryActions number to check
 * @param actionName name of action to check for, as a string
 *
 * @returns boolean, true if the action is contained within the BinaryActions number provided
 */
export function contains(binaryActions: BinaryActions, actionName: keyof typeof BinaryActions): boolean {
  return (binaryActions & BinaryActions[actionName]) === BinaryActions[actionName];
}
