import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AccountType, User } from 'app/shared/common/interfaces/user';
import { FieldGroup } from 'app/shared/interfaces/field-group';
import { AuthService} from 'app/core/services';
import { Subject, of } from 'rxjs';
import { CompanyService } from 'app/company/company.service';
import { UsersService } from 'app/users/users.service';
import { CompanyReference  } from 'app/shared/common/interfaces/company';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { ValidatorService } from 'app/core/services/validator.service';


@Component({
  selector: 'app-account-activation-modal-page',
  templateUrl: './account-activation-modal-page.html',
  styleUrls: ['./account-activation-modal-page.scss'],
})

export class AccountActivationModalPage implements OnInit {
  formGroup: FormGroup;
  fieldGroups: FieldGroup;
  destroy$ = new Subject<void>();
  constructor(
    public modalController: ModalController,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private companyService: CompanyService,
    private usersService: UsersService,
    private validatorService:ValidatorService,
  ) {}

  ngOnInit() {
    this.fieldGroups = this.signupFormUI();     // generates user interface
    this.formGroup = this.formBuilder.group({   // FormGroup/FormBuilder
      email: [''],
      accountType: [''], // THIS MAY CHANGED BASED DUE TO USER LEVEL ACCESS TO THE COMPANY OR MIGHT HAVE TO SET FALSE IN CLIENTSIDE ALWAYS & HIDDEN.
      companyName: [this.companyService.activeCompany.name],
      companyAccountType: [''],
    });
    let ui = {uiData:[{fieldGroups:this.fieldGroups}]} ; //to comply function input format
    this.formGroup = this.validatorService.setValidatorFromUI(this.formGroup,ui) ; 
  }

  ionViewWillLeave() {
    this.formGroup.reset();
  }
  //{uiData:Array<any>,formValidators:Array<any>}
  signupFormUI(): FieldGroup {
    return [
      {
        name: 'emailFormUI',
        fields: [
          {
            name: 'email',       
            label: 'EMAIL',
            type: 'input',
            inputType: 'email',
            placeholder: 'EMAIL',
            bigScreenSize: 12,
            mediumScreenSize: 12,
            smallScreenSize: 12,
            validators:[
              {name:'required',errorType:'required',message:'EMAILREQUIRED'},
              {name:'email',errorType:'email',message:'EMAILPATTERN'}
            ]
          },
          {
            name: 'accountType', //MAY CHANGE TO 'isSuperAdmin' IN FUTURE 
            label: 'ACCOUNTTYPE',
            type: 'input',
            inputType: 'text',
            placeholder: 'ACCOUNTTYPE',
            bigScreenSize: 12,
            mediumScreenSize: 12,
            smallScreenSize: 12,
            validators:[
              {name:'required',errorType:'required',message:'ACCOUNTTYPEREQUIRED'}
            ]
          },
          {
            name: 'companyName',
            label: 'COMPANYNAME',
            type: 'input',
            inputType: 'text',
            placeholder: 'COMPANYNAME',
            readonly:true,
            bigScreenSize: 12,
            mediumScreenSize: 12,
            smallScreenSize: 12,
            validators:[
              {name:'required',errorType:'required',message:'COMPANYNAMEREQUIRED'}
            ]
          },
          {
            name: 'companyAccountType', //MAY CHANGE TO 'admin' IN FUTURE
            label: 'COMPANYACCOUNTTYPE',
            type: 'select',
            options: [
              { text: 'Super Admin', id: 0 },
              { text: 'Admin', id: 1},
              { text: 'Account Manager', id: 2},
              { text: 'User', id: 3 },
            ],
            placeholder: 'COMPANYACCOUNTTYPE',
            bigScreenSize: 12,
            mediumScreenSize: 12,
            smallScreenSize: 12,
            validators:[
              {name:'required',errorType:'required',message:'COMPANYACCOUNTTYPEREQUIRED'}
            ]
          },
        ],
      },
    ];
  }


  onSubmit(): void {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched(); 
      return ; //add alert here in future
    }

    if (this.formGroup.valid) {
      const email: User['email'] = this.formGroup.value.email;
      const accountType = this.formGroup.value.accountType;
      const companyAccountType: AccountType = this.formGroup.value.companyAccountType;
      
      
      this.usersService.getUserId(email).pipe(
        switchMap((userID) => {
          if (!userID) {
            return this.authService.sendActivationLink(email, accountType).pipe(
              tap(() => {}),
              switchMap(() => this.usersService.getUserId(email))
            );
          } else {
            return of(userID);
          }
        }),takeUntil(this.destroy$)
      ).subscribe(
        (userID) => {
          this.addUserToCompany(userID, this.companyService.activeCompany._id, companyAccountType);
          this.formGroup.reset();
        },
        (error) => {
          throw new Error ('Error during account activation' + error);
        }
      );  
    }
  }
  
  
  private addUserToCompany(userID: User['_id'], companyId: CompanyReference['_id'], companyAccountType: AccountType): void {
    if (!companyId) {
      throw new Error('Company not found');
    } else {
      this.companyService.assignUserToCompany(userID, companyId, companyAccountType).subscribe(
        () => {
        },
        (assignUserError) => {
          throw new Error ('Error Assigning User to a company'+ assignUserError);
        }
      );
    }
  }  

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

