import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Panel } from 'app/shared/interfaces/panel';

@Component({
  selector: 'app-expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ExpansionPanelComponent implements OnInit {

  @Input() panel: Panel;

  public showIcon: boolean;

  @HostListener('window:resize', [])
  onResize() {
    this.setShowIcon();
  }

  constructor(public translate: TranslateService) { }

  ngOnInit() {
    this.setShowIcon();
  }

  /**
   * Determines whether icon should be shown based on screen width
   */
  setShowIcon(): void {
    this.showIcon = window.innerWidth > 400;
  }

}
