import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, } from '@angular/forms';
import { ModalController} from '@ionic/angular';
import { FieldGroup, FormValidationOption } from 'app/shared/interfaces/field-group';
import { ValidatorService } from 'app/core/services/validator.service';
import { AuthService } from 'core/services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-password-modal',
  templateUrl: './change-password-modal.page.html',
  styleUrls: ['./change-password-modal.page.scss'],
})
export class ChangePasswordModalPage implements OnInit {
  @Input() userId: string;

  passwordChangeSubscription: Subscription;
  passwordFormGroup: FormGroup;
  passwordFieldGroup: FieldGroup;
  formValidators: FormValidationOption[] ; 
  
  constructor(public modalController: ModalController, private authService: AuthService, private formBuilder: FormBuilder, private validatorService: ValidatorService ) { }

    ngOnInit() {
      this.passwordFieldGroup = this.passwordFormUI();
      this.passwordFormGroup = this.formBuilder.group({
        oldPassword: [''],
        newPassword: [''],
        repeatPassword: [''],
      }, {
      });
        let ui = {
          uiData: [{ fieldGroups: this.passwordFieldGroup }],
          formValidators: [
            {
              name: 'mustMatch',
              fields: ['newPassword', 'repeatPassword'],
              errorType: 'mustMatch',
              message: 'Passwords do not match',
            },
          ],
        };
        this.formValidators = ui.formValidators;
        this.passwordFormGroup = this.validatorService.setValidatorFromUI(this.passwordFormGroup, ui ); 
    }
  
    ionViewWillLeave() {

      if (this.passwordChangeSubscription) {
        this.passwordChangeSubscription.unsubscribe();
      }
      this.passwordFormGroup.reset();
    }
    
    passwordFormUI():FieldGroup {
      return [
        {
          name: 'passwordFormUI',
          fields: [
            {
              name: 'oldPassword',
              label: 'OLDPASSWORD',
              type: 'input',
              inputType: 'password',
              placeholder: 'OLDPASSWORD',
              position: 'floating',
              clearOnEdit:false, // doesn't clear password input when typing again
              toggleIcon: {
                active: 'eye-off-outline',
                inactive: 'eye-outline',
                iconClicked: false,
              },
              bigScreenSize: 12,
              mediumScreenSize: 12,
              smallScreenSize: 12,
              validators: [
                {name:'required',errorType:'required',message:'Password is required'},
              ],
            },
            {
              name: 'newPassword',
              label: 'NEWPASSWORD',
              type: 'input',
              inputType: 'password',
              position: 'floating',
              clearOnEdit:false,
              toggleIcon: {
                active: 'eye-off-outline',
                inactive: 'eye-outline',
                iconClicked: false,
              },
              bigScreenSize: 12,
              mediumScreenSize: 12,
              smallScreenSize: 12,
              validators: [
              {name:'required',errorType:'required',message:'Please Enter a Password'},
              {name:'minlength',errorType:'minlength', value:'2', message:`Must be at least 2 characters long`},
              ],
            },
            {
              name: 'repeatPassword',
              label: 'REPEATPASSWORD',
              type: 'input',
              inputType: 'password',
              position: 'floating',
              clearOnEdit:true, // clears password input when typing again
              toggleIcon: {
                active: 'eye-off-outline',
                inactive: 'eye-outline',
                iconClicked: false,
              },
              bigScreenSize: 12,
              mediumScreenSize: 12,
              smallScreenSize: 12,
              validators: [
                {name:'required',errorType:'required',message:'Please Enter a Password'},
              ],
            }

          ]
        }
      ];
    }
  

  onSubmit() {
    const passwords = this.passwordFormGroup.value; 
    const oldPasswordControl = this.passwordFormGroup.get('oldPassword');
    
    if (this.passwordFormGroup.invalid) {     // Form is invalid; don't proceed with the request
      this.passwordFormGroup.markAllAsTouched();
      return;
    }
  
    this.passwordChangeSubscription = this.authService.changePassword(this.userId, passwords).subscribe({
      next: () => {
        this.modalController.dismiss();
      },
      error: (error) => {
        if (error) {
          oldPasswordControl.markAsTouched(); // Mark old password as touched
          oldPasswordControl.setValue('');    // Clear the old password field
        }
      },
    });
  }
  
  onCancel() {
    this.modalController.dismiss();
  }
}
