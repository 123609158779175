import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController} from '@ionic/angular';
import { TableService } from 'app/core/services';
import { ChildDocumentService } from 'app/core/services/create-child-document.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-action-popover',
  templateUrl: './create-action-popover.component.html',
  styleUrls: ['./create-action-popover.component.scss'],
})
export class CreateActionPopoverComponent implements OnInit {
  createOptions : Array<{name:string,nextRoute:string[],icon?:string}> ;
  currentView : string = '' ;//set by table component activeRoute,(view) - activeRoute + _view / (list) - activeRoute
  //selected : Array<RecordType> =[] ; //following table component select/dbclick function
  selectedSubscription : Subscription ; 
  constructor( public popoverController: PopoverController,public router:Router,
               public tableService:TableService,public createChildDocumentService:ChildDocumentService,
             ) {}

  //dismissing pop over allow it oninit again.
  ngOnInit() {
    this.currentView = this.router.url.split('?')[0];
    //set parent document, create the routing option
    //data passed from view will take as 1 element array 
    //TODO : Option is dynamic in feature-dynamic-create-rules without length context
    this.selectedSubscription = this.tableService.selectedSubscription.subscribe(
      selected =>{
        this.createChildDocumentService.parentDocument = selected ;
        switch(this.currentView){
          case '/orders':
            if(selected.length==0) {
              this.createOptions = [
                {name:'Order',nextRoute:['orders','form']}
              ];
            } 
            if(selected.length>0){
              this.createOptions = [
                {name:'Invoice',nextRoute:['invoices','form']},
                {name:'ASN',nextRoute:['asns','form']}
              ];
            }
            break;
          case '/invoices':
            if(selected.length==0) {
              this.createOptions = [
                {name:'Invoice',nextRoute:['invoices','form']}
              ];
            } else { 
              this.createOptions = [
                {name:'Invoice',nextRoute:['invoices','form']},
                {name:'ASN',nextRoute:['asns','form']}
              ];
            }
            break;
          case '/orders/form':
            this.createOptions = [
              {name:'Invoice',nextRoute:['invoices','form']},
              {name:'ASN',nextRoute:['asns','form']}
            ]; 
            break;
          case '/invoices/form':
            this.createOptions = [
              {name:'ASN',nextRoute:['asns','form']}
            ]; 
            break;
          case '/asns/form':
            this.createOptions = [
              {name:'Invoice',nextRoute:['invoices','form']}
            ];
            break;
          case '/users':
            this.createOptions = [{name:'Users',nextRoute:['users','form'],icon:'card'}];
            break;
          case '/products':
            this.createOptions = [
              {name:'Product',nextRoute:['products','form']}
            ];
            break;
          case '/price-list':
            this.createOptions = [
              {name:'Price List',nextRoute:['price-list','form']}
            ];
            break;
          case '/locations':
            this.createOptions = [
              {name:'Location',nextRoute:['locations','form']}
            ];
            break;
          default:
            break;
        }      
        
      }
    )
  }
  
  //user clicks on an option in pop over
  selectOption(nextRoute:string[]){
    this.createChildDocumentService.parentView = this.router.url.split('/')[1];
    this.router.navigate(nextRoute, { queryParams: {editMode: true}}); //For create option, edit mode must be true
    this.popoverController.dismiss();
    this.selectedSubscription.unsubscribe();
  }

}
