import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { InvoiceService } from 'app/documents/invoices/invoice.service';
import { LineItem } from 'app/shared/common/interfaces/lineItem';
import { combineLatest, Subject } from 'rxjs';

@Component({
  selector: 'vat-summary-table',
  templateUrl: './vat-summary-table.component.html',
  styleUrls: ['./vat-summary-table.component.scss'],
})
export class VatSummaryTableComponent implements OnInit {
  @ViewChild('vatTable') vatTable: NgxDatatableModule;
  @Input() lineItems$: Subject<Partial<LineItem>[]>;
  lineItems: Partial<LineItem>[];
  surcharge: number;
  vatBreakdownHeaders: any;
  vatBreakdown: any;
  vatRate_S = 0.2;
  vatRate_L = 0.17;
  vatRate_Z = 0;

  constructor(private invoiceService: InvoiceService) {
    this.vatBreakdownHeaders = [
      { name: 'Line Count', prop: 'lineCount' },
      { name: 'VAT Code', prop: 'vatCode' },
      { name: 'percent', prop: 'percent' },
      { name: 'Lines Net', prop: 'linesNet' },
      { name: 'Total Net', prop: 'totalNet' },
      { name: 'VAT Amount', prop: 'vatAmount' },
      { name: 'Total', prop: 'total' },
    ];
  }

  ngOnInit() {
    combineLatest([
      this.invoiceService.currentSurcharge,
      this.lineItems$
    ]).subscribe(([surcharge, lineItems]) => {
      this.surcharge = surcharge;
      this.lineItems = lineItems;
      this.vatBreakdown = [];
  
      let vatCodes = ['S', 'Z', 'L'];
      let vatRates = [this.vatRate_S, this.vatRate_Z, this.vatRate_L];
  
      for (let i = 0; i < vatCodes.length; i++) {
        let numberOfVatCode = 0;
        let linesNet = 0;
        for (let j = 0; j < lineItems?.length; j++) {
          if (lineItems[j].vatCode == vatCodes[i]) {
            numberOfVatCode++;
            linesNet = linesNet + (lineItems[j].price * lineItems[j].quantity);
          }
        }
        this.surcharge = +this.surcharge;
        if (numberOfVatCode > 0) {
          this.vatBreakdown.push({
            lineCount: numberOfVatCode,
            vatCode: vatCodes[i],
            percent: (vatRates[i] * 100).toFixed(2), //THIS IS VAT%
            linesNet: (Math.round(linesNet * 100) / 100).toFixed(2),
            surchargesAmount: this.surcharge.toFixed(2),
            totalNet: ((Math.round(linesNet * 100) / 100) + this.surcharge).toFixed(2),
            vatAmount: (Number(linesNet) * vatRates[i]).toFixed(2),
            total: ((Math.round(linesNet * 100) / 100) + this.surcharge + (Number(linesNet) * vatRates[i])).toFixed(2) //SURCHARGES PLUS VAT PLUS TOTALS
          })
        }
      }
    });
  }

}