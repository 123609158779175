import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'core/services';

// Redirects user to NewUsers component if /login route is requested
// To be used in routing modules
@Injectable({providedIn: 'root'})
export class IsLoggedIn {

  constructor(private router: Router, private authService: AuthService) {  }

  resolve(): void {
    if (this.authService.isAuth) {
      this.router.navigate(['/dashboard']);
    }
  }

}