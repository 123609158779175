import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { ExportDocument } from 'app/shared/common/interfaces/global';

import { Order } from 'common/interfaces/order';
import { Product } from 'common/interfaces/product';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient) { }

  getOrder(orderId: string): Observable<Order> {
    return this.http.get<Order>(`order/${orderId}`);
  }

  exportDocument(orderIds: string[]): Observable<ExportDocument> {
    return this.http.post<ExportDocument>('order/export', { orderIds });
  }

  createOrder(order: Order) {
    return this.http.post('order', {order}, {responseType: 'text'});
  }
  updateOrder(order: Order) {
  return this.http.put('order', { order }, { responseType: 'text' });
}

  deleteOrders(orderIds: string[]) {
    return this.http.delete('order', { body: { orderIds }, responseType: 'text' });
  }

  // FIXME: create product functionality
  // this is dummy product data
  dummyProductArray(): Partial<Product>[] {
      return [{
        _id: '0',
        supplierId: 'wheeeeeeeeeee',
        supplierItemCode: '55152',
        barcodeNumber: '502445422215',
        caseSize: 2,
        description: 'Product Ayyy',
      },
      {
        _id: '1',
        supplierId: 'EEEE',
        supplierItemCode: '1124',
        barcodeNumber: '502221233560',
        caseSize: 5,
        description: 'Product Bee',
      },
      {
        _id: '2',
        supplierId: 'EEEE',
        supplierItemCode: '22124',
        barcodeNumber: '502115457795554',
        caseSize: 8,
        description: 'Product Si',
      },
      {
        _id: '3',
        supplierId: '949995',
        supplierItemCode: '66+59=125',
        barcodeNumber: '504445422215',
        caseSize: 2,
        description: 'Product Eee',
      },
      {
        _id: '4',
        supplierId: '1/0',
        supplierItemCode: '77546',
        barcodeNumber: '503363331255',
        caseSize: 30,
        description: 'Product Day',
      },
    ]
  }

}
