import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { AuthService } from 'core/services';
import { PrivilegesTree, UserPrivilege } from 'common/interfaces/privileges';
import { Company, CompanyReference } from 'common/interfaces/company';
// import { ActionPrivilegesService } from 'shared/action-privileges/action-privileges.service';
import { CompanyDetailsComponent } from 'shared/components';

import { CompanyService } from 'app/company/company.service';


@Component({
  selector: 'app-user-trading-partner-modal',
  templateUrl: './user-trading-partner-modal.page.html',
  styleUrls: ['./user-trading-partner-modal.page.scss'],
})
export class UserTradingPartnerModalPage implements OnInit {
  // userPrivilege (below) is retrieved from the componentProps property in the object passed into this.modalController.create()
  userPrivilege: UserPrivilege;
  isSuperAdmin = false;
  privilegesTree: PrivilegesTree;
  editMode: boolean;
  companyReference: CompanyReference;
  selectedTradingPartner: CompanyReference;

  @ViewChild('tradePartner') public companyDetails: CompanyDetailsComponent;

  constructor(private modalController: ModalController,
    //  private actionPrivilegesService: ActionPrivilegesService,
    private companyService: CompanyService, private authService: AuthService) { }

  ngOnInit() {  }

  ionViewWillEnter() {
    // TODO: refactor privileges
    // this.privilegesTree = this.actionPrivilegesService.convertPrivilegesToTree(this.userPrivilege.actionPrivileges);
    this.companyService.getCompany(this.companyReference._id).subscribe((company: Company) => {
      this.companyDetails.company = company;
      this.companyDetails.companyForm.patchValue({
        name: company.name,
        vatNumber: company.vatNumber,
        address: {
          addressLine1: company.address.addressLine1,
          addressLine2: company.address.addressLine2,
          addressLine3: company.address.addressLine3,
          addressLine4: company.address.addressLine4
        }
      });

      // Remove companies that are already assigned as a TP to a user
      this.companyDetails.company.tradingPartners.forEach((tp, index) => {
        this.companyReference.tradingPartners.forEach(excludedTp => {
          if (excludedTp._id === tp._id) {
            this.companyDetails.company.tradingPartners.splice(index, 1);
          }
        });
      });
    });
    this.isSuperAdmin = this.authService.isSuperAdmin();
  }

  onTradingPartnerSelect(partner: CompanyReference) {
    this.selectedTradingPartner = partner;
  }

  removeTradingPartner(removedTradingPartner: CompanyReference) {
    this.companyReference.tradingPartners.forEach((tp, index) => {
      if (removedTradingPartner._id === tp._id) {
        this.companyReference.tradingPartners.splice(index, 1);
      }
    });
  }

  addTradingPartner() {
    if (!this.selectedTradingPartner) return;
    if (!this.companyReference.tradingPartners.some(tp => tp._id === this.selectedTradingPartner._id)) {
      this.companyReference.tradingPartners.push(this.selectedTradingPartner);
    }
  }

  save() {
    // TODO: put this back in
    // this.userPrivilege.actionPrivileges = this.actionPrivilegesService.convertPrivilegesToDB(this.privilegesTree);
    this.modalController.dismiss({company: this.companyReference});
    // , userPrivilege: this.userPrivilege});
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
