import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';
import { CheckPrivilegeService } from 'app/core/services/check-privilege.service';

import { Observable, Subject } from 'rxjs';
import { finalize, map, take } from 'rxjs/operators';

import { Action, BinaryActions } from 'app/shared/common/actions';
import { AuthService } from './auth.service';

import * as PrivilegeHelpers from 'app/shared/common/modules/privilege-helpers';

import { Platform } from '@ionic/angular';

/**
 * Used to control which buttons are visible in the action bar
 *
 * Also used to handle click events in the action bar
 *
 */
@Injectable({
  providedIn: 'root'
})
export class ActionBarService {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private checkPrivilegeService: CheckPrivilegeService,
              public authService: AuthService,
              private platform: Platform) {

    // subscription to route url change
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.url = val.url.split('?')[0]; // current route, excluding parameters
        this.params = this.route.snapshot.queryParams; // parameters
        if (this.authService.user.value) this.renderButtons(); // prevents buttons from rendering if no user logged in
      }
    });
  }

  set recordsSelected(recordsSelected: number) {
    this._recordsSelected = recordsSelected;
    this.renderButtons();
  }

  private url: any;
  private params: Params;
  private _recordsSelected: number;

  /* Object containing a reference to BinaryActions masks associated with each record type. */
  /* The key is the route url which points to that record type; the value is a BinaryActions mask. */
  private recordTypeMasks = {
    '/users/form': PrivilegeHelpers.allExcept(BinaryActions.selectMode),
    '/users': PrivilegeHelpers.allExcept(BinaryActions.save),
    '/company/form': PrivilegeHelpers.allExcept(BinaryActions.selectMode),
    '/company/disabled': BinaryActions.none,
    '/locations': PrivilegeHelpers.allExcept(BinaryActions.save),
    '/locations/form': PrivilegeHelpers.allExcept(BinaryActions.selectMode),
    '/products': PrivilegeHelpers.allExcept(BinaryActions.save),
    '/products/form': PrivilegeHelpers.allExcept(BinaryActions.selectMode),
    '/price-list': PrivilegeHelpers.allExcept(BinaryActions.save),
    '/price-list/form': PrivilegeHelpers.allExcept(BinaryActions.selectMode),
    '/orders': PrivilegeHelpers.allExcept(BinaryActions.save),
    '/orders/form': PrivilegeHelpers.allExcept(BinaryActions.selectMode),
    '/invoices': PrivilegeHelpers.allExcept(BinaryActions.save),
    '/invoices/form': PrivilegeHelpers.allExcept(BinaryActions.selectMode),
  };


  private buttonEvent$ = new Subject<Action>();
  public readonly buttonEventSubscription = this.buttonEvent$.asObservable().pipe(
    // When .unsubscribe is called, finalize() is used for clean up
    finalize(() => {
      this.recordsSelected = 0;
    })
  );

  private backButtonEvent$ = new Subject<Action>();
  public readonly backButtonEventSubscription = this.backButtonEvent$.asObservable();

  private renderButtons$ = new Subject<BinaryActions>();
  public readonly renderButtonsSubscription = this.renderButtons$.asObservable();

  public buttonEvent(event: Action) {
    this.buttonEvent$.next(event);
  }

  public backButtonEvent() {
    // Put null because after updating packages compiler says its expecting an argument but got 0
    this.backButtonEvent$.next(null);
  }

  public renderButtons() {
    this.checkVisibleButtons().subscribe(binaryActions => {
      this.renderButtons$.next(binaryActions);
    })
  }

  /**
   *
   * @returns A number, who's binary form represents a selection of actions.
   *
   * Uses button visibility masks to produce a BinaryActions number that only contains actions present in all masks.
   *
   * The result is to be consumed by the action-bar component to determine which buttons should be visible on the action bar.
   *
   */
  private checkVisibleButtons(): Observable<BinaryActions> {
    const viewTypeMask = this.recordTypeMasks[this.url];
    let selectedMask = BinaryActions.all;

    // Conditions for displaying edit button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this._recordsSelected === 1 || this.url.split('/')[2] === 'form'? BinaryActions.none : BinaryActions.edit
    ))
    // Conditions for choosing between save and edit buttons
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this.params.editMode === 'true' ? BinaryActions.edit : BinaryActions.save)
    );
    // Conditions for displaying delete button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this._recordsSelected >= 1 || this.url.split('/')[2] === 'form'? BinaryActions.none : BinaryActions.delete)
    );
    // Conditions for displaying export button
    // TODO: should only be displayed on orders table/view for now
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this._recordsSelected >= 1 || this.url.split('/')[2] === 'form'? BinaryActions.none : BinaryActions.export)
    );
    // Conditions for displaying open button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this._recordsSelected === 1? BinaryActions.none : BinaryActions.open)
    );
      
    // Conditions for displaying copy button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this._recordsSelected === 1 || this.url.split('/')[2] === 'form'? BinaryActions.none : BinaryActions.duplicate)
    );

    // Conditions for displaying create button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this.params.editMode === 'true'? BinaryActions.create :BinaryActions.none )
    )
    // Conditions for displaying filter button
    selectedMask = PrivilegeHelpers.removeFrom(
      selectedMask,
      (this._recordsSelected === 1 || this.url.split('/')[2] === 'form'? BinaryActions.filter : BinaryActions.none)
    );

    const deviceMask = this.platform.is('mobile') ? PrivilegeHelpers.allExcept(BinaryActions.export) : PrivilegeHelpers.allExcept(BinaryActions.selectMode);

    // The below is temporary. It performs the checkPrivilege funtion with the overload that returns an array of trading partners and
    // is hardcoded to the 0th element.
    // TODO: The tradingPartnerId should be made available here and a more specific overload should be used.
    return this.checkPrivilegeService.checkPrivilege(this.url.split('/')[1]).pipe(
      take(1),
      map(tradingPartnerBinaryActions => {
        // uncomment below line for debugging; allows  buttons to be shown regardless of privilege
        return PrivilegeHelpers.combineMasks(viewTypeMask, selectedMask, deviceMask);
        return PrivilegeHelpers.combineMasks(
          viewTypeMask,
          selectedMask,
          tradingPartnerBinaryActions[0].binaryActions
        ); // to be changed; read comment above
      })
    );
  }
}
