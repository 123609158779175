import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UIService {

  constructor(private http: HttpClient) { }

  getUiDataByCompanyAndType(companyId: string, recordType: string, relatedRecordType?: string) {
    let url = `ui/recordType/${companyId}/${recordType}` ; 
    url = relatedRecordType? url+ "/"+ relatedRecordType: url ; 
    return this.http.get<any>(url);
  }
  //TODO: refactor it after UI update
  getUiDataByTradingPartnerId(tradingPartnerId: string[], recordType: string) {
    let url = `ui/tradingPartnerId/${tradingPartnerId[0]}/${recordType}` ; 
    url = tradingPartnerId.length>1 ? `ui/tradingPartnerId/${tradingPartnerId[0]}/${recordType}/${tradingPartnerId[1]}`:url
    return this.http.get<any>(url);
  }

}
