import { Pipe, PipeTransform } from '@angular/core';

// More at https://stackoverflow.com/questions/64911518/access-nested-object-with-n-level-deep-object-using-square-bracket-notation
// Probably not the best thing to edit or even touch
// Can be used anywhere where bracket notation is used in the templates to access nestes object properties
@Pipe({name: 'propertyAccessor'})
export class PropertyAccessor implements PipeTransform {
  transform(record: Object, property: string): Object {
    property = property.replace(/\[(\w+)\]/g, '.$1');
    property = property.replace(/^\./, '');
    let propertyArray = property.split('.');
    for (let i = 0, total = propertyArray.length; i < total; ++i) {
        let currectProperty = propertyArray[i];
        if (currectProperty in record) {
            record = record[currectProperty];
        } else {
            return;
        }
    }
    return record;
  }
}