import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { MenuController, NavController, Platform, IonMenu  } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from 'app/company/company.service';
import { AuthService, SelectCompanyService } from 'app/core/services';
import { CompanyReference } from 'app/shared/common/interfaces/company';
import { FieldGroup } from 'app/shared/interfaces/field-group';
import { SignedUser } from 'app/shared/user';
import { ReplaySubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @Input() isAuthenticated;

  sidenavForm: FormGroup;
  fieldGroups: FieldGroup;

  public selectedIndex = 0;
  
  user: SignedUser;
  
  companyName = new FormControl();

  selectedCompany: CompanyReference;
  selectedCompany$ = new ReplaySubject<CompanyReference>();
  availableCompanies$ = new ReplaySubject<CompanyReference[]>();

  // cache data for user to change between disable company and enable one
  lastRoutes: Array<{id:string,index:number}> = [];

  // isAuthenticated = false;

  public userSubscription: Subscription;

  //suspecting that this appPages should not be static?
  public appPages = [
    {
      title: 'ORDERS',
      translateKey: 'ORDERS',
      url: '/orders', //default table component
      icon: 'document',
      canView: true
    },
    {
      title: 'INVOICES',
      translateKey: 'INVOICES',
      url: '/invoices',
      icon: 'card',
      canView: true
    },
    {
      title: 'REPORTS',
      translateKey: 'REPORTS',
      url: '/reports',
      icon: 'clipboard',
      canView: true
    },
    {
      title: 'ASNS',
      translateKey: 'ASNS',
      url: '/asn',
      icon: 'cube',
      canView: true
    },
    {
      title: 'USERS',
      translateKey: 'USERS',
      url: '/users',
      icon: 'people',
      canView: true
    },
    {
      title: 'PRODUCTS',
      translateKey: 'PRODUCTS',
      url: '/products',
      icon: 'basket',
      canView: true
    },
    {
      title: 'PRICELISTS',
      translateKey: 'PRICELISTS',
      url: '/price-list',
      icon: 'diamond',
      canView: true
    },
    {
      title: 'LOCATIONS',
      translateKey: 'LOCATIONS',
      url: '/locations',
      icon: 'location',
      canView: true
    },
    // last element always needs to be logout
    {
      title: 'LOGOUT',
      translateKey: 'LOGOUT',
      url: '',
      icon: 'exit',
      canView: true
    },
  ];

  constructor(
    public authService: AuthService,
    private router: Router,
    private companyService: CompanyService,
    private translate: TranslateService,
    private menuController: MenuController,
    private navController: NavController,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private selectCompanyService: SelectCompanyService,
    private menu: IonMenu
  ) {
    this.initializeApp();
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

    initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.show();
    });
  }

  ngOnInit() {
    this.sidenavForm = new FormGroup({
      companyName: new FormControl() // This causes error if left out, however it is not needed as the selected company is not saved to DB
    });

    this.fieldGroups = [
      {
      name:'autocomplete-company',
      //label:'AUTOCOMPLETECOMPANY',
        fields: [{
          name: 'companyName',
          label: 'COMPANIES',
          type: 'autocomplete',
          selectedRecord$: this.selectedCompany$,
          availableRecords$: this.availableCompanies$,
          bigScreenSize: 12,
          mediumScreenSize:12,
          smallScreenSize:12
        }]
      }
    ]

    this.userSubscription = this.authService.user.subscribe(user => {
      this.user = user;
      this.isAuthenticated = !!user;

      this.companyService.getCompanies().subscribe(companies => {
        this.availableCompanies$.next(companies);
      });


      this.assignCurrentCompany(this.user?.companies[0]);

      this.translateMenuText();
    });

    this.selectedCompany$.subscribe(company => {
      this.sidenavForm?.patchValue({companyName: this.selectedCompany?.name});
      if (company && company != this.selectedCompany) { //To stop infinite recursion.
        this.selectedCompany = company;
        this.assignCurrentCompany(company);
      }
    });
  }
  //click event on side nav ['order','invoice'...etc]
  onClick(i: number, isLast: boolean) {
    // not sure if there is a better way to call logout in the sidenav
    this.selectedIndex = i;
    if (isLast) {
      this.menuController.enable(false);
      this.menuController.swipeGesture(false);
      this.authService.logout();
    }
    //route to page by appPages array above
    this.companyService.getCompanies().subscribe(companies=>{
      let company = companies.find( company=> company._id == this.companyService.activeCompany._id) ;
      if(company.disabled&& !(this.authService.isSuperAdmin()) ){
        this.selectedIndex = -1;// -1 unset any selected route
        this.navController.navigateForward(['/company/disabled']);
      }else{
        this.navController.navigateForward([this.appPages[i].url]);
      }
    });
  }

  translateMenuText(){
    this.appPages.forEach(x=>{
      this.translate.get(x.translateKey).subscribe((text:string)=>{
          x.title = text;
      });
    });
  }
  
  async onLangClick(lang: string){
    this.translate.use(lang);
    this.translateMenuText();
  }
  dismissMenu() {
    this.menu.close();
  }
  
  viewAccount() {
    this.dismissMenu(); // Dismiss the side menu
    this.router.navigate(['/users/form'], { queryParams: { id: this.user._id }});
  }

  viewCompany() {
    if (this.selectedCompany) {
      this.dismissMenu(); 
      this.router.navigate(['/company/form'], {queryParams: {id: this.selectedCompany._id, editMode: false}});
    }
    return;
  }

  createCompany() {
    this.dismissMenu();
    this.router.navigate(['/company/form'], {queryParams: { editMode: true }});
  }

  editCompany(){
    this.dismissMenu(); 
    this.router.navigate(['/company/form'], {queryParams: { id: this.selectedCompany._id,editMode: true }});
  }
  //When a side nav change current company.
  assignCurrentCompany(company: CompanyReference) {
    if(this.companyService.activeCompany){
      this.cacheLastRoute(this.companyService.activeCompany._id,this.router.url.split('/')[1]);
    }
    this.selectedCompany = company;
    this.companyService.activeCompany = this.selectedCompany;
    this.selectedCompany$.next(company);
    // TODO: this is not a proper way to disable doc types for disabled company. Will change in future cycles
    this.companyService.getCompanies().subscribe(companies=>{ //this component is using user company which had no disable properties
      let company = companies.find( company=> company._id == this.companyService.activeCompany._id) ;
      if(company?.disabled){
        this.navController.navigateForward(['/company/disabled']);
        this.selectedIndex  = -1 ;
        return; //instantly goes to disabled page if disabled
      }
      this.appPages.forEach(x=>x.canView = true); //when it is false
      this.selectCompanyService.selectCompanyEvent(company);// getRecords in table when change company
      let lastRoute = this.lastRoutes.find(route=> route.id==company._id) ; 
      let nextRoute ; 
      if(lastRoute == undefined){
        nextRoute = [''];
        this.selectedIndex = -1;
      }else{
        nextRoute = this.appPages[lastRoute.index].url  ;
        this.selectedIndex = lastRoute.index ; // update css  
      }
      this.navController.navigateForward(nextRoute);
    });
  }

  //cache the last valid route for enabled company.
  cacheLastRoute(id:string, route:string){

    let index = this.appPages.findIndex( page => page.url.split('/')[1] == route);

    if(index == -1) {
      return; // appPage don't have this route, don't cache 
    }    
    let routeToCache = {id: id, index: index}; 
    let routeToReplace = this.lastRoutes.findIndex(route=> route.id==id);
    if(routeToReplace>-1){
      this.lastRoutes[routeToReplace] = routeToCache ;
      return;
    }
    this.lastRoutes.push(routeToCache);
  }


}


