import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { PrivilegesTree, PrivilegesTreeItem, PrivilegesTreeAction } from 'common/interfaces/privileges';

@Component({
  selector: 'app-action-privileges',
  templateUrl: './action-privileges.component.html',
  styleUrls: ['./action-privileges.component.scss'],
})
export class ActionPrivilegesComponent implements OnInit {
  public _privilegesTree: PrivilegesTree;

  @Output()
  privilegesTreeChange = new EventEmitter<PrivilegesTree>();
  @Input()
  get privilegesTree() {
    return this._privilegesTree;
  }

  set privilegesTree(privilegesTree) {
    this._privilegesTree = privilegesTree;
    this.privilegesTreeChange.emit(this._privilegesTree);
  }

  constructor() { }

  // solution adapted from https://www.freakyjolly.com/ionic-select-unselect-checkboxes-with-ideterminate/#.X0Z-UchKiUk

  /**
   *
   * @param privilegesTreeItem An object that contains the data required to display a record type and its children in the privileges tree
   *
   * toggles all children actions for a record type
   *
   */
  toggleParent(privilegesTreeItem: PrivilegesTreeItem): void {
    setTimeout(() => {
      privilegesTreeItem.actions.forEach((action: PrivilegesTreeAction) => {
        action.value = privilegesTreeItem.parentToggled;
      });
    });
  }

  /**
   *
   * @param privilegesTreeItem An object that contains the data required to display a record type and its children in the privileges tree
   *
   * Evaluates if a parent checkbox should be changed to checked, unchecked or indeterminate when a child is toggled
   *
   */
  toggleChild(privilegesTreeItem: PrivilegesTreeItem): void {
    const totalItems: number = privilegesTreeItem.actions.length;
    let toggled = 0;
    privilegesTreeItem.actions.forEach((action: PrivilegesTreeAction) => {
      if (action.value) { toggled++; }
    });
    if (toggled > 0 && toggled < totalItems) { // If even one item is toggled but not all
      privilegesTreeItem.isIndeterminate = true;
      privilegesTreeItem.parentToggled = false;
    } else if (toggled === totalItems) { // If all are toggled
      privilegesTreeItem.parentToggled = true;
      privilegesTreeItem.isIndeterminate = false;
    } else { // If none are toggled
      privilegesTreeItem.isIndeterminate = false;
      privilegesTreeItem.parentToggled = false;
    }

  }

  ngOnInit() { }

}
