import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { IsInvited, IsLoggedIn } from 'core/guards';

import { AuthComponent } from './auth.component';
import { VerifyComponent } from './verify/verify.component';


const routes: Routes = [
  {
    path: 'login',
    component: AuthComponent,
    resolve: [IsLoggedIn]
  },
  {
    path: 'verify',
    component: VerifyComponent,
    resolve: [IsLoggedIn],
    canActivate: [IsInvited], 
  },
  {
    path: 'reset',
    component: VerifyComponent,
    resolve: [IsLoggedIn],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
