import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ViewWillLeave } from '@ionic/angular';

import { Company } from 'common/interfaces/company';
import { TradingPartner } from 'common/interfaces/tradePartner';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit, ViewWillLeave {

  @Input() company: Partial<Company & TradingPartner> | Partial<Company>;
  @Input() editMode = false;
  @Input() isTradePartner = false;

  companyForm: FormGroup;

  constructor() { }

  ngOnInit() {

    this.companyForm = new FormGroup({
      name: new FormControl(),
      vatNumber: new FormControl(),
      companyIdentifiers: new FormControl(), // This should be an array of items and not a free text
      address: new FormGroup({
        addressLine1: new FormControl(),
        addressLine2: new FormControl(),
        addressLine3: new FormControl(),
        addressLine4: new FormControl(),
        postcode: new FormControl(),
        countryCode: new FormControl(),
      }),
      gln: new FormControl(),
      prefix: new FormControl(),
      buyerCode: new FormControl(),
      supplierCode: new FormControl()
    });
  }

  ionViewWillLeave() {
    this.companyForm.reset();
  }
}
