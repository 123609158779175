import { Directive } from '@angular/core';
import { AlertController } from '@ionic/angular';

export interface OverlayEventDetail<T = any> {
  data?: T;
  role?: string;
}

@Directive({
  selector: 'AlertDialogComponent'
})
export class AlertDialogComponent {

  constructor(private alertCtrl: AlertController){

  }

  public async basicAlert(header: string, subHeader: string, message: string,
    buttonText: string): Promise<OverlayEventDetail<any>>{
    const alert: HTMLIonAlertElement = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons: [buttonText]
    });

    await alert.present();
    return alert.onDidDismiss();
  }

  public async confirmAlert(header: string, subHeader: string, message: string,
  confirmButtonText: string, cancelButtonText: string, confirmHandler: (value: any) => boolean | void | {
    [key: string]: any;
  }, cancelHandler:(value: any) => boolean | void | {
    [key: string]: any;
  }): Promise<OverlayEventDetail<any>>{
    const alert: HTMLIonAlertElement = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons: [
        {
          text: cancelButtonText,
          role: 'cancel',
          handler: cancelHandler
        },
        {
          text: confirmButtonText,
          role: 'confirm',
          handler: confirmHandler
        }
      ]
    });

    await alert.present();
    return alert.onDidDismiss();
  }
}
