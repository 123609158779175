import { NgModule } from '@angular/core';

import { SharedModule } from 'shared/shared.module';

import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { VerifyComponent } from './verify/verify.component';

@NgModule({
  declarations: [
    AuthComponent,
    VerifyComponent
  ],
  providers: [],
  imports: [
    AuthRoutingModule,
    SharedModule,
  ],
  exports: [
  ]
})
export class AuthModule {}
