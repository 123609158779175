import { Injectable } from '@angular/core';
import { RecordType } from 'app/shared/common/interfaces/global';
import { RecordPageRequest } from 'app/shared/common/interfaces/search';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  private selectMode$ = new BehaviorSubject<boolean>(false);
  private selected$ = new BehaviorSubject<Array<RecordType>>([]);
  public readonly selectedSubscription = this.selected$.asObservable();
  
  private recordPageRequest$ = new ReplaySubject<RecordPageRequest>();
  public readonly recordPageRequestSubscription = this.recordPageRequest$.asObservable();

  public readonly selecModeSubscription = this.selectMode$.asObservable();
  public set selected(records:Array<RecordType>) {
    this.selected$.next(records);
  }
  public set selectMode(selectMode:boolean) {
    this.selectMode$.next(selectMode);
  }

  public set recordPageRequest(recordPageRequest:RecordPageRequest){
    this.recordPageRequest$.next(recordPageRequest);
  }

  constructor() { }
}
