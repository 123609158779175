import { Component, OnInit, OnDestroy} from '@angular/core';

import { Subscription } from 'rxjs';

import { ActionBarService, AuthService, FilterService, TableService } from 'core/services';
import { Action, BinaryActions } from 'app/shared/common/actions';
import * as PrivilegeHelpers from 'app/shared/common/modules/privilege-helpers';
import { PopoverController } from '@ionic/angular';
import { CreateActionPopoverComponent } from './create-action-popover/create-action-popover.component';
import { FilterActionPopoverComponent } from './filter-action-popover/filter-action-popover.component';
import { CompanyService } from 'app/company/company.service';
import { CompanyReference } from 'app/shared/common/interfaces/company';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
  styleUrls: ['./action-bar.component.scss'],
})
export class ActionBarComponent implements OnInit, OnDestroy {
  renderButtonsSubscription: Subscription;
  selectModeEventSubscription: Subscription;
  roleMsg='';
  selectMode = false ; 

  constructor(public authService: AuthService, public actionBarService: ActionBarService,public popoverController: PopoverController,
    public filterService:FilterService, public tableService:TableService, public companyService : CompanyService) {

  }

  public showBackButton = true; // TODO: create a more sophisticated method to determine if back button is visible
  // TODO: add unarchive. See template for icon name
  public actionButtons = [
    {
      icon: 'file-tray-full',
      name: 'ARCHIVE',
      visible: false,
      action: Action.archive,
      backgroundColor: 'inherit'
    },
    {
      icon: 'grid',
      name: 'SELECTMODE',
      visible: false,
      action: Action.selectMode,
      backgroundColor: 'inherit'
    },
    {
      icon: 'download',
      name: 'EXPORT',
      visible: false,
      action: Action.export,
      backgroundColor: 'inherit'
    },
    {
      icon: 'print',
      name: 'PRINT',
      visible: false,
      action: Action.print,
      backgroundColor: 'inherit'
    },
    {
      icon: 'copy',
      name: 'COPY',
      visible: false,
      action: Action.duplicate,
      backgroundColor: 'inherit'
    },
    {
      icon: 'create',
      name: 'CHANGESTATUS',
      visible: false,
      action: Action.changeStatus,
      backgroundColor: 'inherit'
    },
    {
      icon: 'lock-closed',
      name: 'LOCK',
      visible: false,
      action: Action.lock,
      backgroundColor: 'inherit'
    },
    {
      icon: 'trash',
      name: 'DELETE',
      visible: false,
      action: Action.delete,
      backgroundColor: 'inherit'
    },
    {
      icon: 'mail-open',
      name: 'OPEN',
      visible: false,
      action: Action.open,
      backgroundColor: 'inherit'
    },
    {
      icon: 'add',
      name: 'CREATE',
      visible: false,
      action: Action.create,
      backgroundColor: 'inherit'
    },
    {
      icon: 'pencil',
      name: 'EDIT',
      visible: false,
      action: Action.edit,
      backgroundColor: 'inherit'
    },
    {
      icon: 'save',
      name: 'SAVE',
      visible: false,
      action: Action.save,
      backgroundColor: 'inherit'
    },
    {
      icon: 'send',
      name: 'RELEASE',
      visible: false,
      action: Action.release,
      backgroundColor: 'inherit'
    },
    {
      icon: 'search',
      name: 'SEARCH',
      visible: false,
      action: Action.search,
      backgroundColor: 'inherit'
    },
    {
      icon: 'ellipsis-vertical',
      name: 'ELLIPSIS',
      visible: false,
      action: Action.ellipsis,
      backgroundColor: 'inherit'
    },
    {
      icon: 'funnel',
      name : 'FILTER',
      visible:false,
      action: Action.filter,
      backgroundColor: 'inherit'
    }
  ];

  ngOnInit() {
    this.selectModeEventSubscription = this.tableService.selecModeSubscription.subscribe(selectMode=>{
      this.selectMode = selectMode; 
      if(this.selectMode == true){
        this.actionButtons.find(button=>button.action == Action.selectMode).backgroundColor='#1c4d67';
      }else{
        this.actionButtons.find(button=>button.action == Action.selectMode).backgroundColor='inherit';
      }
    });
    //will be called each time nav change
    this.renderButtonsSubscription = this.actionBarService.renderButtonsSubscription.subscribe(binaryActions => {
      /* loop over action buttons and decide if they should be visible */
      let company : CompanyReference; 
      this.companyService.getCompanies().subscribe(companies=>{
        company = companies.find(company => company._id == this.companyService.activeCompany._id );
        this.actionButtons.forEach(item => {
          const actionName = Action[item.action] as keyof typeof BinaryActions;
          if (PrivilegeHelpers.contains(binaryActions, actionName) && (!company.disabled||(this.authService.isSuperAdmin())) ) {
            item.visible = true;
          } else {
            item.visible = false;
          }
        });
      })
    });
    
  }

  ngOnDestroy(): void {
    this.renderButtonsSubscription.unsubscribe();
    this.selectModeEventSubscription.unsubscribe();
  }

  onClick(index: number,$event: Event) {
    this.actionBarService.buttonEvent(this.actionButtons[index].action);
    //hard code create action here because it is linked to action bar layout
    if(index==9){this.presentCreateActionPopover($event);};
    if(index==15){this.presentFilterActionPopover($event);};
  }

  onBack() {
    this.actionBarService.backButtonEvent();
  }

  async presentCreateActionPopover(e: Event) {
    const popover = await this.popoverController.create({
      component: CreateActionPopoverComponent,
      event: e,
    });
    await popover.present();
    const {} = await popover.onDidDismiss();
  }
  
  //present a drop down for fitler option in action bar
  async presentFilterActionPopover(e: Event) {
    const popover = await this.popoverController.create({
      component: FilterActionPopoverComponent,
      event: e,
      componentProps:{}
    });
    await popover.present();
    const {} = await popover.onDidDismiss();
  }
}
