import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import { RecordPageRequest } from '../common/interfaces/search';
import { RecordType } from '../common/interfaces/global';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private http: HttpClient) { }

  getRecords(recordPageRequest: RecordPageRequest, companyId: string): Observable<{records: RecordType[]; total: number;}> {
    return this.http.post<{records: RecordType[]; total: number}>('search/list/', {recordPageRequest, companyId} );
  }
  //this api is over riding for table to called instead of filter popover
  applyFilterSearch(recordPageRequest:RecordPageRequest,companyId:string): Observable<{records: RecordType[]; total: number;}> {  
    return this.http.post<{records: RecordType[]; total: number}>('search/filter/', {recordPageRequest,companyId} )
  }
}
