import { Injectable } from '@angular/core';
import { CompanyService } from 'app/company/company.service';
import { CompanyReference } from 'app/shared/common/interfaces/company';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectCompanyService {
  private selectCompanyEvent$ = new Subject<CompanyReference>();
  public readonly selectCompanyEventSubscription = this.selectCompanyEvent$.asObservable()

  constructor(public companyService:CompanyService) { }

  public selectCompanyEvent(company:CompanyReference) {
    this.selectCompanyEvent$.next(company);
  }

}
