import { Injectable } from '@angular/core';
import { CompanyService } from 'app/company/company.service';
import { Company, CompanyReference } from 'app/shared/common/interfaces/company';

@Injectable({ providedIn: 'root' })
export class HelperService {

  constructor(private companyService:CompanyService) { }

  /**
   * Converts Company type to CompanyReference
   *
   */
  companyToReference(company: Company): CompanyReference {
    // tslint:disable-next-line
    return { _id: company._id, name: company.name, tradingPartners: company.tradingPartners, disabled: company.disabled } as CompanyReference;
  }

  addCreatedBy(document:any):any{
    if(!document.hasOwnProperty('createdBy')){
      document.createdBy = this.companyService.activeCompany._id;
    }
    return document ; 
  }

}
