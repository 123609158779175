import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { IonicModule } from '@ionic/angular';

import { ActionPrivilegesComponent } from './action-privileges/action-privileges.component';
import { CompanyListComponent, CompanyDetailsComponent  } from './components';
import { FieldContainerComponent } from './components/field-container/field-container.component';
import { ExpansionPanelComponent } from './components/expansion-panel/expansion-panel.component';
import { TranslateModule } from '@ngx-translate/core';
import { LineItemTableComponent } from './components/line-item-table/line-item-table.component';
import { TableComponent } from './components/table/table.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TabsComponent } from './components/tabs/tabs.component';
import { PropertyAccessor } from './pipes/property-accessor.pipe';
import { LineItemModalComponent } from './modals/line-item-modal/line-item-modal.component';
import { FilterModalComponent } from './modals/filter-modal/filter-modal.component';

import { AlertDialogComponent } from './components/alert-dialog/alert-dialog';
import { AddProductsModalComponent } from './modals/add-products-modal/add-products-modal.component';
import { VatSummaryTableComponent } from './components/vat-summary-table/vat-summary-table.component';
import { VatTotalsTableComponent } from './components/vat-totals-table/vat-totals-table.component';

@NgModule({
  imports: [
    CommonModule,
    NgxDatatableModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatIconModule,
    MatAutocompleteModule,
    MatInputModule,
    TranslateModule,
  ],
  exports: [
    CommonModule,
    ActionPrivilegesComponent,
    ExpansionPanelComponent,
    FieldContainerComponent,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    MatIconModule,
    CompanyListComponent,
    CompanyDetailsComponent,
    LineItemTableComponent,
    VatSummaryTableComponent,
    VatTotalsTableComponent,
    AlertDialogComponent,
    TranslateModule,
    TabsComponent,
    TableComponent,
    LineItemModalComponent,
    FilterModalComponent
  ],
  declarations: [
    ActionPrivilegesComponent,
    ExpansionPanelComponent,
    FieldContainerComponent,
    CompanyListComponent,
    CompanyDetailsComponent,
    LineItemTableComponent,
    VatSummaryTableComponent,
    VatTotalsTableComponent,
    TabsComponent,
    TableComponent,
    PropertyAccessor,
    LineItemModalComponent,
    AlertDialogComponent,
    AddProductsModalComponent,
    FilterModalComponent
  ],
  providers: [
    { provide: AlertDialogComponent }
  ]
})
export class SharedModule { }
