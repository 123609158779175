import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { Product } from 'common/interfaces/product';
import { RecordPageRequest } from 'app/shared/common/interfaces/search';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  getProducts(companyId: string, recordPageRequest: RecordPageRequest): Observable<{products:Product[]; total: number}> {
    return this.http.post<{products:Product[]; total: number}>('product/list/', {companyId, recordPageRequest} );
  }

  getProduct(id: string): Observable<Product> {
    return this.http.get<Product>(`product/${id}`);
  }

  createProduct(product: Product) {
    return this.http.post('product', {product}, {responseType: 'text'});
  }

  updateProduct(product: Product) {
    return this.http.put('product', { product }, { responseType: 'text' });
  }
  
  deleteProducts(productIds: string[]) {
    return this.http.delete('product', { body: { productIds }, responseType: 'text' });
  }

}