import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnDestroy{

  @Input() tooltip = ''; // The text for the tooltip to display
  @Input() delay? = 100; // Optional delay input, in ms
  @Input() position? = "bottom";

  private myPopup;
  private timer;

  constructor(private el: ElementRef) { }

  ngOnDestroy(): void {
    if (this.myPopup) { this.myPopup.remove() }
  }

  @HostListener('mouseenter') onMouseEnter() {
    this.timer = setTimeout(() => {
      let x = this.el.nativeElement.getBoundingClientRect().left + this.el.nativeElement.offsetWidth / 2; // Get the middle of the element
      let y = this.el.nativeElement.getBoundingClientRect().top + this.el.nativeElement.offsetHeight + 6; // Get the bottom of the element, plus a little extra
      if(this.position == "right"){
        x = this.el.nativeElement.getBoundingClientRect().left + this.el.nativeElement.offsetWidth + 6; // Get the middle of the element
        y = this.el.nativeElement.getBoundingClientRect().top + 6; // Get the bottom of the element, plus a little extra
      }else if(this.position == "left"){
        x = this.el.nativeElement.getBoundingClientRect().left - 6;
        y = this.el.nativeElement.getBoundingClientRect().top + 6;
      }else if(this.position == "top"){
        x = this.el.nativeElement.getBoundingClientRect().left + this.el.nativeElement.offsetWidth / 2; // Get the middle of the element
        y = this.el.nativeElement.getBoundingClientRect().top - 6; // Get the bottom of the element, plus a little extra
      }

      this.createTooltipPopup(x, y);
    }, this.delay)
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.timer) clearTimeout(this.timer);
    if (this.myPopup) { this.myPopup.remove() }
  }

  private createTooltipPopup(x: number, y: number) {
    let popup = document.createElement('div');
    popup.innerHTML = this.tooltip;
    popup.setAttribute("class", "tooltip-container");
    popup.style.top = y.toString() + "px";
    popup.style.left = x.toString() + "px";
    if(this.position == "left"){
      popup.style.left = (x - (4 * this.tooltip.length)).toString() + "px";
    } else if(this.position == "top"){
      popup.style.top = (y - 28).toString() + "px";
    }
    document.body.appendChild(popup);
    this.myPopup = popup;
    /*
    setTimeout(() => {
      if (this.myPopup) this.myPopup.remove();
    }, 5000); // Remove tooltip after 5 seconds
    */
  }

}
