import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { AuthService } from 'core/services';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
})
export class ResetPasswordComponent implements OnInit {

  emailForm = new FormGroup({
    email: new FormControl()
  });

  constructor(public modalController: ModalController, private authService: AuthService) { }

  ngOnInit() {}

  onClick(): void {
    this.authService.resetPassword(this.emailForm.get('email').value).subscribe(data => {
      console.log(data);
    });
    this.modalController.dismiss();
  }
}
