import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { ExportDocument } from 'app/shared/common/interfaces/global';

import { Invoice } from 'common/interfaces/invoice';
import { Product } from 'common/interfaces/product';
import { RecordPageRequest } from 'app/shared/common/interfaces/search';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  private surchargeSource = new BehaviorSubject<number>(0); // initial value set to 0
  currentSurcharge = this.surchargeSource.asObservable();

  constructor(private http: HttpClient) { }

  getInvoices(companyId: string, recordPageRequest: RecordPageRequest): Observable<{invoices:Invoice[]; total: number}> {
    return this.http.post<{invoices:Invoice[]; total: number}>('invoice/list', { companyId, recordPageRequest });
  }

  updateSurcharge(surcharge: number) {
    this.surchargeSource.next(surcharge);
  }

  getInvoice(invoiceId: string): Observable<Invoice> {
    return this.http.get<Invoice>(`invoice/${invoiceId}`);
  }

  exportDocument(invoiceIds: string[]): Observable<ExportDocument> {
    return this.http.post<ExportDocument>('invoice/export', { invoiceIds });
  }

  createInvoice(invoice: Invoice) {
    return this.http.post('invoice', {invoice}, {responseType: 'text'});
  }

  updateInvoice(invoice: Invoice) {
    return this.http.put('invoice', {invoice}, {responseType: 'text'});
  }

  deleteInvoices(invoiceIds: string[]) {
    return this.http.delete('invoice', { body: { invoiceIds }, responseType: 'text' });
  }

  dummyProductArray(): Partial<Product>[] {
    return [{
      _id: '0',
      supplierId: 'wheeeeeeeeeee',
      supplierItemCode: '55152',
      barcodeNumber: '502445422215',
      caseSize: 2,
      description: 'Product Ayyy',
      price: 50
    },
    {
      _id: '1',
      supplierId: 'EEEE',
      supplierItemCode: '1124',
      barcodeNumber: '502221233560',
      caseSize: 5,
      description: 'Product Bee',
      price: 60
    },
    {
      _id: '2',
      supplierId: 'EEEE',
      supplierItemCode: '22124',
      barcodeNumber: '502115457795554',
      caseSize: 8,
      description: 'Product Si',
      price: 70
    },
    {
      _id: '3',
      supplierId: '949995',
      supplierItemCode: '66+59=125',
      barcodeNumber: '504445422215',
      caseSize: 2,
      description: 'Product Eee',
      price: 80
    },
    {
      _id: '4',
      supplierId: '1/0',
      supplierItemCode: '77546',
      barcodeNumber: '503363331255',
      caseSize: 30,
      description: 'Product Day',
      price: 90
    },
  ]
}

}
