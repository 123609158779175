import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController} from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { CompanyService } from 'app/company/company.service';
import { AuthService, FilterService, TableService } from 'app/core/services';
import { FilterGroup, Filter} from 'app/shared/common/interfaces/filter';
import { RecordPageRequest } from 'app/shared/common/interfaces/search';
import { FilterModalComponent } from 'app/shared/modals/filter-modal/filter-modal.component';
import { SearchService } from 'app/shared/services/search.service';
import { UIService } from 'app/ui/ui.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-action-popover',
  templateUrl: './filter-action-popover.component.html',
  styleUrls: ['./filter-action-popover.component.scss'],
})
export class FilterActionPopoverComponent implements OnInit {

  filterGroups : Array<FilterGroup>;
  recordPageRequest: RecordPageRequest;
  filterSubscription: Subscription;
  recordPageRequestSubscription: Subscription;
  userSubscription: Subscription;
  companyId : string; 
  fieldGroups: any ; // TODO: update type after ui-in-server branch
  userId : string ;
  cssClass : string = "filter-modal";

  activeRoute : string ; 

  constructor( public popoverController: PopoverController, public modalController: ModalController,
    public filterService : FilterService,public companyService:CompanyService,
    public tableService: TableService,public searchService: SearchService, public router: Router,
    public authService: AuthService, public uiService : UIService) {}

  //on init will be called again if pop over is dismissed
  ngOnInit() {
    this.activeRoute = this.router.url.split('/')[1] ; 
    this.companyId = this.companyService.activeCompany._id ; 
    this.uiService.getUiDataByCompanyAndType(this.companyId,'filter',this.activeRoute ).subscribe(ui=>{
      this.fieldGroups = ui.uiData[0].fieldGroups; //all filter field group should has only 1 group
    })
    
    //get array of filterGroup here
    this.filterSubscription = this.filterService.filterGroupSubscription.subscribe( filterGroups=>
      this.filterGroups = filterGroups 
    );
    this.recordPageRequestSubscription = this.tableService.recordPageRequestSubscription.subscribe( recordPageRequest=>{ 
      this.recordPageRequest = recordPageRequest ; 
    });
    this.userSubscription = this.authService.user.subscribe(user=>this.userId = user._id) ; 
    
  }
  
  closePopover() {
    this.recordPageRequestSubscription.unsubscribe();
    this.filterSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
    this.popoverController.dismiss();
  }

  //open search filter modal and load in all default filter
  async addFilter(){
    let filterGroup : FilterGroup; let inputFieldGroup = JSON.parse(JSON.stringify(this.fieldGroups)); //clone object because we use same modal component with same field for different action
    const filterModal = await this.modalController.create({
      component: FilterModalComponent,
      componentProps: {fieldGroups: inputFieldGroup},
      cssClass:this.cssClass
    });
    filterModal.present();
    const { data, role } = await filterModal.onWillDismiss();
    filterGroup = {userId:this.userId ,activeRecord:this.activeRoute,filters:data } ;
    if(role=='apply'){
      this.applyFilterSearch(data);
    }
    if(role=='save'){
      this.filterService.addFilter(filterGroup).subscribe(()=>{
        this.refreshFilter();
      });
    }
    //create modal will never have a modal for you to delete in a first place
  }

  //open search filter modal and load in the field to edit
  async editFilter( filterGroup:FilterGroup,event:any){
    let filterGroupId = filterGroup._id ; 
    let inputFieldGroup = JSON.parse(JSON.stringify(this.fieldGroups)); //clone object because we use same modal component with same field for different action
    const filterModal = await this.modalController.create({ 
      component: FilterModalComponent,
      componentProps: {fieldGroups:inputFieldGroup ,filters:filterGroup.filters},
      cssClass:this.cssClass
    });
    filterModal.present();
    const { data, role } = await filterModal.onWillDismiss();
    filterGroup = {_id:filterGroupId, userId:this.userId ,activeRecord:this.activeRoute,filters:data } ;
    if(role=='apply'){
      this.applyFilterSearch(data);
    }
    if(role=='save'){
      this.filterService.editFilter(filterGroup).subscribe(()=>{
        this.refreshFilter();
      });
    }
    if(role==='delete'){
      this.deleteFilter(filterGroupId);
    }
    event.stopPropagation();//stop ion-item apply filter emit after hitting edit button
  }

  //call api and return to update table service result
  applyFilterSearch(modalFilters: Array<Filter>){
    let filterToSearch : Array<Filter>;
    filterToSearch = modalFilters.filter(filter=> filter.name!='filterName' && filter.value?.[0]!=null &&filter.applied==true);
    if( filterToSearch.length > 0){//>= 1 filter is applied
      this.recordPageRequest.filters= filterToSearch ; 
      this.tableService.recordPageRequest = this.recordPageRequest;
      this.filterService.applyFilterSearch(this.recordPageRequest,this.companyId);
    }
  }

  //call api to update all field group in current document route
  refreshFilter(){
    this.filterService.getFilterGroups(this.userId);
  }

  deleteFilter(filterGroupId:string){
    this.filterService.removeFilter(filterGroupId).subscribe(() => this.refreshFilter());
  }
  //reset table result without any filter
  disableFilter(){
    this.recordPageRequest.filters = null ; 
    this.tableService.recordPageRequest = this.recordPageRequest ; //for pagination
    this.filterService.disableFilterSearch(this.recordPageRequest,this.companyId) ; 
  }

  getFilterName(filterGroup:FilterGroup):String{
    let filterName = '';
    filterName = filterGroup.filters.find(field=>field.name=='filterName').value[0];
    return filterName ; 
  }

  ionViewWillLeave(){
    this.userSubscription.unsubscribe();
  }

}
