import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthService } from 'core/services';
import { SignedUser } from 'app/shared/user';
import { AccountType } from 'app/shared/common/interfaces/user';

@Injectable({ providedIn: 'root' })
export class IsSuperAdmin implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const user: SignedUser = this.authService.user.value;
      if (user.accountType === AccountType.superAdmin) {
        return true;
      }

      return this.router.createUrlTree(['/login']);

  }

}



