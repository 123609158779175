// import { Metadata } from './global';
import { CompanyReference } from './company';

export interface User {
  _id: string;
  firstName: string;
  lastName: string;
  dateFormat;
  timezone;
  companies: CompanyReference[];
  roles;
  brands;
  areas;
  darkmode: boolean;
  email: string;
  password: string;
  verified: boolean;
  accountType: AccountType;
  deleted: boolean;
  employmentTenure?: Array<string>;
  // userType: UserType;
  // metaData: Metadata;

  // shownColumns: DocumentTypes<Columns>; // moved from UserConfig
  // settings: Settings; // moved from UserConfig
}

export interface UserCredentials {
  email: User['email'];
  password: User['password'];
}

export enum UserType {
  Admin,
  Buyer,
  Supplier,
  // ...
}

export enum AccountType {
  superAdmin,
  admin,
  accountManager,
  user
}