import { Component, OnInit, ViewChild } from '@angular/core';
import { UKDatePipe } from 'app/shared/pipes/date.pipe';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController, getPlatforms, IonInfiniteScroll, ViewDidEnter, ViewWillLeave, ViewWillEnter} from '@ionic/angular';
import { DatatableComponent, SelectionType, ColumnMode } from '@swimlane/ngx-datatable';

import { Observable, Subscription } from 'rxjs';

import { ActionBarService, AuthService, FilterService, SelectCompanyService } from 'core/services';
import { SearchService } from 'shared/services/search.service';
import { Action } from 'common/actions';
import {  RecordPageRequest } from 'app/shared/common/interfaces/search';

import { CompanyService } from 'app/company/company.service';
import { OrderService } from 'app/documents/orders/order.service';
import { InvoiceService } from 'app/documents/invoices/invoice.service';
import { LocationsService } from 'app/locations/locations.service';
import { UsersService } from 'app/users/users.service';
import { TableService } from 'app/core/services/table.service';
import { ProductsService } from 'app/products/products.service';
import { PriceListService } from 'app/price-list/price-list.service';
import { RecordType } from 'app/shared/common/interfaces/global';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'record-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, ViewWillLeave, ViewWillEnter, ViewDidEnter {

  @ViewChild('datatable', {static: false}) table: DatatableComponent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  readonly headerHeight = 50;
  readonly rowHeight = 50;

  searchForm: FormGroup;

  recordPageRequest: RecordPageRequest;

  activeRoute: string;
  userId: string;

  totalRows = 0;

  loadingIndicator = true;
  reorderable = true;
  SelectionType = SelectionType;

  rows: RecordType[] = [];
  selected: RecordType[] = [];

  columns = []; //name: display text, prop: key of data, translateKey: key used for translation... 

  columnMode = ColumnMode;

  buttonEventSubscription: Subscription;
  backButtonEventSubscription: Subscription;
  selectCompanyEventSubscription: Subscription;
  recordPageRequestSubscription: Subscription;
  filterResultSubscription:Subscription;
  userSubscription: Subscription;
  selectModeEventSubscription: Subscription;

  selectionType = SelectionType;
  selectMode = false;

  selectedPageOption: number = 10 ; 
  pageOptions : Array<number> = [10,25,50,100,150,200,250]; 
  pageOption : number  ; 

  inputValue :number ; 

  constructor(private searchService: SearchService, private userService: UsersService, private orderService: OrderService, private locationService: LocationsService,
              private actionBarService: ActionBarService, public router: Router, private navController: NavController, private companyService: CompanyService,public tableService:TableService,
              public filterService: FilterService, public authService:AuthService,
              private selectCompanyService:SelectCompanyService, private productService: ProductsService, private priceListService: PriceListService, private invoiceService: InvoiceService,
              private translate:TranslateService
              ) {
              }

  ngOnInit() {
    this.activeRoute = this.router.url.split('/')[1];
    this.recordPageRequestSubscription = this.tableService.recordPageRequestSubscription.subscribe( recordPageRequest=>{ 
      this.recordPageRequest = recordPageRequest; //read any other component alternated record page request
    });
    this.searchForm = new FormGroup({
      filter: new FormControl('')
    });
    //For multiple documents we can create the /document route specifying the types in the url
    switch (this.activeRoute) {
      case 'locations':
        this.recordPageRequest = {
          recordTypeName: 'DeliveryLocation',
          order: 'asc',
          sortByField: 'name',
          stringFilter: '',
          page: 0,
          pageSize: 20
        }
        this.columns = [
          { name: 'Name', prop: 'name' ,translateKey:'NAME' }, 
          { name: 'Address Line 1', prop: 'address.addressLine1' ,translateKey:'ADDRESSLINE1' },
          { name: 'Postcode', prop: 'address.postcode' ,translateKey:'POSTCODE' }
        ];
        break;
      case 'products':
        this.recordPageRequest = {
          recordTypeName: 'Product',
          order: 'asc',
          sortByField: 'name',
          stringFilter: '',
          page: 0,
          pageSize: 20
        }
        this.columns = [
          { name: 'Name', prop: 'name' ,translateKey:'NAME' }, 
          { name: 'Description', prop: 'description' ,translateKey:'DESCRIPTION'  }
        ];
        break;
      case 'price-list':
        this.recordPageRequest = {
          recordTypeName: 'PriceList',
          order: 'asc',
          sortByField: 'name',
          stringFilter: '',
          page: 0,
          pageSize: 20,
        }
        this.columns = [
          { name: 'Name', prop: 'name' ,translateKey:'NAME' }, 
          { name: 'Description', prop: 'description' ,translateKey:'DESCRIPTION' }, 
          { name: 'Associated with', prop: 'associatedWith' ,translateKey:'ASSOCIATEDWITH' } 
        ];
        break;
      case 'users':
        this.recordPageRequest = {
            recordTypeName: 'User',
            order: 'asc',
            sortByField: 'name',
            stringFilter: '',
            page: 0,
            pageSize: 20,
        }
        this.columns = [
          { name: 'First Name', prop: 'firstName' ,translateKey:'FIRSTNAME' }, 
          { name: 'Last Name', prop: 'lastName' ,translateKey:'LASTNAME' },
          { name: 'Email', prop: 'email' ,translateKey:'EMAIL' }];
        break;
      case 'orders':
        this.recordPageRequest = {
              recordTypeName: 'Order',
              order: 'asc',
              sortByField: 'documentNumber',
              stringFilter: '',
              page: 0,
              pageSize: 10,
              recipientKey:'supplierDetails.companyID'
        };
        let ordersPipe: UKDatePipe = new UKDatePipe('en_GB');
        this.columns = [
          { name: 'Document Number' , prop: 'documentNumber', translateKey:'DOCUMENTNUMBER'},
          { name: 'Document Date', prop: 'documentDate', translateKey:'DOCUMENTDATE', type: 'datetime', pipe: ordersPipe }
        ];

        break;
      case 'invoices':
        this.recordPageRequest = {
              recordTypeName: 'Invoice',
              order: 'asc',
              sortByField: 'name',
              stringFilter: '',
              page: 0,
              pageSize: 20,
              recipientKey:'buyerDetails.companyID'
        }
        let invoicesPipe: UKDatePipe = new UKDatePipe('en_GB');
        this.columns = [
          { name: 'Document Number', prop: 'documentNumber' ,translateKey:'DOCUMENTNUMBER'},
          { name: 'Document Date', prop: 'documentDate', type: 'datetime', pipe: invoicesPipe ,translateKey:'DOCUMENTDATE' }
        ];
        break;
      default:
        break;
    }

    this.translateColumnText();//translate instantly 
    this.translate.onLangChange.subscribe( ()=>{
      this.translateColumnText(); //translate again each time lang change
    })

  }
  ionViewDidEnter(): void {
    this.tableService.recordPageRequest = this.recordPageRequest ;    
    this.tableService.selected = [] ; 
    this.selectModeEventSubscription = this.tableService.selecModeSubscription.subscribe( selectMode =>{
      this.selectMode = selectMode ; //subscribe selectMode since its real time data useful
      if(this.selectMode == false){
        this.selected = [];
      }
    })
    this.getRecords();
    this.userSubscription = this.authService.user.subscribe(user=>{
      this.userId=user._id;
      this.getFilterGroups();
    })
    // Needed for mobile view to avoid duplicate 0th page request
    if (!this.isDesktop()) {
      this.recordPageRequest.page++;
    }
  }
  ionViewWillEnter() {

    this.buttonEventSubscription = this.actionBarService.buttonEventSubscription.subscribe(event => {
      switch (event) {
        case Action.edit:
          this.editAction();
          break;
        case Action.selectMode:
          this.tableService.selectMode = !this.selectMode;
          break;
        case Action.create:
          this.createAction();
          break;
        case Action.open:
          this.viewAction();
          break;
        case Action.delete:
          this.deleteAction();
          break;
        case Action.duplicate:
          this.duplicateAction();
          break;
        case Action.filter:
          this.filterAction();
        default:
          break;
      }
    });

    this.backButtonEventSubscription = this.actionBarService.backButtonEventSubscription.subscribe(() => {
        this.backAction();
      })
    //side nav event trigger this 
    this.selectCompanyEventSubscription = this.selectCompanyService.selectCompanyEventSubscription.subscribe(() =>{
      this.getRecords();
    })

    //subscrbe rows to filter pop over applyfilter action result
    this.filterResultSubscription = this.filterService.filterResultSubscription.subscribe( data => {
      //for desktop
      if(this.isDesktop){
        this.rows = [...data.records];
        this.totalRows = data.total;
        this.loadingIndicator = false;
      }else{
        if (!(this.rows.length === this.totalRows)){
          this.rows.push(...data.records);
          this.totalRows = data.total;
          this.recordPageRequest.page++;
        }
      }
    })


  }

  ngOnDestroy() { }

  ionViewWillLeave() {
    this.selected = [];
    this.buttonEventSubscription.unsubscribe();
    this.backButtonEventSubscription.unsubscribe();
    this.selectCompanyEventSubscription.unsubscribe();
    this.selectModeEventSubscription.unsubscribe();
    this.rows = [];
    this.recordPageRequest.page = 0;
    this.recordPageRequestSubscription.unsubscribe();
  }

  // Event triggered on element select from the table
  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    this.tableService.selected = this.selected ; 
    this.actionBarService.recordsSelected = this.selected.length;
  }

  // Event on row click
  onActivate(event) {
    // This needs to be revisited. on a single click, up to 4 events could be emitted.
    // This is the shortest way to ensure the user view is not opened on a single click on a checkbox
    if ((!this.selectMode && event.type === 'dblclick')&& event.event.target.localName != 'input' ) { //excluded dbclick on input(checkbox) target
      this.tableService.selected = [event.row] as RecordType[];
      //this is where you left the record list view 
      this.navController.navigateForward([this.activeRoute, 'form'], { queryParams: { id: event.row._id, editMode: false } });
    }
  }

  // Event triggered on page change or scroll
  onPage(event) {
    console.log('paged', event);
  }

  toggleExpandRow(row) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  isDesktop(): boolean {
    return getPlatforms().includes('desktop');
  }
  editAction(): void
  editAction(record?: RecordType): void {
    if (record) {
      this.router.navigate([this.activeRoute, 'form'], { queryParams: { id: this.selected[0]._id, editMode: true } });
    } else {
      this.router.navigate([this.activeRoute, 'form'], { queryParams: { id: this.selected[0]._id, editMode: true } });
    }
  }

  viewAction(): void;
  viewAction(record?: RecordType): void {
    if (record) {
      this.router.navigate([this.activeRoute, 'form'], { queryParams: { id: record._id, editMode: false} });
    } else {
      this.router.navigate([this.activeRoute, 'form'], { queryParams: { id: this.selected[0]._id, editMode: false} });
    }
  };

  deleteAction(): void {
    const selectedIds = this.selected.map(x => x._id);
    switch (this.activeRoute) {
      case 'locations':
        this.locationService.deleteLocations(selectedIds).subscribe(() => {
          this.getRecords();
        });
        break;
      case 'products':
        this.productService.deleteProducts(selectedIds).subscribe(() => {
          this.getRecords();
        });
        break;
      case 'price-list':
        this.priceListService.deletePriceLists(selectedIds).subscribe(() => {
          this.getRecords();
        });
        break;
      case 'users':
        this.userService.deleteUsers(selectedIds).subscribe(() => {
          this.getRecords();
        });
        break;
      case 'orders':
        this.orderService.deleteOrders(selectedIds).subscribe(() => {
          this.getRecords();
        });
        break;
      case 'invoices':
        this.invoiceService.deleteInvoices(selectedIds).subscribe(() => {
          this.getRecords();
        });
        break;
      default:
        break;
    }
    // Clearing out the selected elements after deletion
    this.selected = [];
    this.tableService.selected = [] ; 
  }

  //Overloaded functions for cards
  duplicateAction(): void;
  duplicateAction(record?:  RecordType): void {
    if (record) {
      this.router.navigate([this.activeRoute, 'form'], { queryParams: { id: record._id, editMode: true, duplicate: true } });
    } else {
      this.router.navigate([this.activeRoute, 'form'], { queryParams: { id: this.selected[0]._id, editMode: true, duplicate: true } });
    }
  }

  backAction(): void {
    this.selected = [];
    if(this.selectMode){
      //we reset the whole select mode thing here
      this.tableService.selectMode = false; 
    }else{
      this.navController.navigateRoot(['dashboard']);
    }
  }

  createAction(): void {
    //this.router.navigate([this.activeRoute, 'form'], { queryParams: {editMode: true}});
  }

  //create modal
  async filterAction() {
    //popover is in action bar instead because css
  }

  setFilter(filter: string) {
    this.searchForm.controls.filter.setValue(filter);
  }

  applyStringFilter(){
    this.recordPageRequest.stringFilter = this.searchForm.get('filter').value;//states unchange until user hit another button
    this.applyRecordPageRequest();
  }

  //update table with an updated recordPageRequest
  applyRecordPageRequest() {
    this.rows = [];
    if (!this.isDesktop()) {
      this.recordPageRequest.page = 0;
      this.rows = [];
      this.getMobileRecords();
    } else {
      this.getRecords();
    }
    //after this recordPageRequest applied, broadcast the new recordPageRequest to subscriber 
    this.tableService.recordPageRequest = this.recordPageRequest ; 
  }

  getId(row) {
    return row._id;
  }

  sort(event) {
    this.recordPageRequest.order = event.newValue;
    this.recordPageRequest.sortByField = event.column.prop;
    this.applyRecordPageRequest();
  }

  setPage(event) {
    //custom footer only have page so work around is -1 to make it an offset.
    this.recordPageRequest.page = event.page-1;
    this.applyRecordPageRequest();
  }

  getRecords() {
    let searchCallBack : Observable<{records: RecordType[]; total: number;}> ; 
    if(this.recordPageRequest.filters == null){
      searchCallBack = this.searchService.getRecords(this.recordPageRequest,this.companyService.activeCompany._id);
    }
    if(this.recordPageRequest.filters!=null && this.recordPageRequest.filters.length>0){
      searchCallBack = this.searchService.applyFilterSearch( this.recordPageRequest, this.companyService.activeCompany._id);
    }
    searchCallBack.subscribe(data=>{
      this.rows = [...data.records];
      this.totalRows = data.total;
      this.loadingIndicator = false;
    })
  }

  getMobileRecords(event?: any) {
    let searchCallBack : Observable<{records: RecordType[]; total: number;}> ; 
    if(this.recordPageRequest.filters == null){
      searchCallBack = this.searchService.getRecords(this.recordPageRequest,this.companyService.activeCompany._id);
    }
    if(this.recordPageRequest.filters!=null && this.recordPageRequest.filters.length>0){
      searchCallBack = this.searchService.applyFilterSearch( this.recordPageRequest, this.companyService.activeCompany._id);
    }
    searchCallBack.subscribe(data=>{
      if ((this.rows.length === this.totalRows) && event) {
        event.target.disabled = true;
      } else {
        this.rows.push(...data.records);
        this.totalRows = data.total;
        this.recordPageRequest.page++;
        event?.target.complete();
      }
    })
  }

  selectRecord(record: RecordType) {
    let isSelected = this.selected.some(x => x._id === record._id);
    if (isSelected) {
      let selectedIndex = this.selected.findIndex(x => x._id === record._id);
      this.selected.splice(selectedIndex, 1);
    } else {
      this.selected.push(record);
    }
    this.tableService.selected = this.selected ;
    this.actionBarService.recordsSelected = this.selected.length;
  }

  isSelected(record): boolean {
    return this.selected.some(x => x._id === record._id);
  }

  doInfinite(event) {
    this.getMobileRecords(event);
  }

  log(value:any){
    console.log("Parse Value: " +  JSON.stringify(value) + " | " +  Object.prototype.toString.call(value));
  }
  parseValue(value: any){
    console.log("Parse Value: " +  JSON.stringify(value) + " | " +  Object.prototype.toString.call(value));
    let d = new Date(value);
    if(d.getTime() === d.getTime()){
      //This is a Date
      return this.formatDate(d);
    }else{
      return value;
    }
  }

  //TODO: maybe restructure this to use .format function
  //https://www.w3docs.com/snippets/javascript/how-to-format-a-javascript-date.html
  formatDate(date: Date) {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    let year = date.getFullYear();
    let hour = '' + date.getHours();
    let minute = '' + date.getMinutes();
    let second = '' + date.getSeconds();

    if (hour.length < 2)
        hour = '0' + hour;
    if (minute.length < 2)
        minute = '0' + minute;
    if (second.length < 2)
        second = '0' + second;
    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-') + ' ' + [hour, minute, second].join(':');
  }

  //each checkbox checked value
  public getChecked(row: any): boolean {
    let selectedObjAsString = this.selected.map(function(item){return JSON.stringify(item)});
    var contains = selectedObjAsString.includes(JSON.stringify(row));
    return contains ; 
  }

  //each checkbox change  function
  public onCheckboxChange(row: any): void {
    if (this.getChecked(row) === false) {
      // add the row to selected
      this.selected.push(row);  
    } else {
      // indexOf don't work on array of object but only array of string
      let selectedObjAsString = this.selected.map(function(item){return JSON.stringify(item)});
      let removeElementIndex = selectedObjAsString.indexOf(JSON.stringify(row));
      this.selected.splice(removeElementIndex, 1); 
    }
    this.tableService.selected = this.selected ;
  }

  //headerbox value
  public getCheckedAll(): boolean{
    let selectedObjAsString = this.selected.map(function(item){return JSON.stringify(item)});
    let rowsObjAsString = this.rows.map(function(item){return JSON.stringify(item)});
    //if less are selected then all rows, impossible to have all rows
    if(selectedObjAsString.length<rowsObjAsString.length){ 
      return false; 
    }
    else{
      let equals = true;
      //more or eqaully selected as row, once selected don't includes a row, its not all rows.
      rowsObjAsString.forEach(row => {
        if(!selectedObjAsString.includes(row)){
          equals = false ; 
        }
      });
      return equals ; 
    }
  }

  //headerbox change function
  public onCheckboxAllChange(){
    let selectedObjAsString = this.selected.map(function(item){return JSON.stringify(item)});
    let rowsObjAsString = this.rows.map(function(item){return JSON.stringify(item)});
    let totalNotIncludedRow = 0 ; 
    rowsObjAsString.forEach( row => {
      if(!selectedObjAsString.includes(row)){totalNotIncludedRow++}
    });
    //if some or no rows are included -> include the excluded
    if(totalNotIncludedRow>0){
      rowsObjAsString.forEach( row => {
        if(!selectedObjAsString.includes(row)){
          this.selected.push(this.rows[rowsObjAsString.indexOf(row)]);
          this.tableService.selected = this.selected ;  
        }
      });
    }else{
    //else all rows are included -> exclude all
      rowsObjAsString.forEach( row => {
        //re-map for real time data
        let newSelectedObjAsString = this.selected.map(function(item){return JSON.stringify(item)});
        this.selected.splice( newSelectedObjAsString.indexOf(row), 1);
        this.tableService.selected = this.selected ;  
      });
    }
  }

  onEnterPageSize(event){
    if(event.target.value==''){return;}
    this.recordPageRequest.pageSize = Number(event.target.value) ;
    this.applyRecordPageRequest();
  }

  //get all filter group each time route is change in table
  getFilterGroups(){    
    this.filterService.getFilterGroups(this.userId);
  }

  updateStringFilter(){
    this.recordPageRequest.stringFilter = this.searchForm.get('filter').value;
    this.tableService.recordPageRequest = this.recordPageRequest;
  }
  //function after long press on card in mobile mode
  //since this function will execute before clickCard, it is only for select Mode controlling
  pressCard(){
    if(this.selectMode==false){
      this.tableService.selectMode = true ; 
    }
  }

  clickCard(record:RecordType){
    if(!this.selectMode){
      (this.viewAction as (record?:RecordType) => void)(record);
      return;
    }
    if(this.selectMode){
      this.selectRecord(record);
      if(this.selected.length==0){
        this.tableService.selectMode = false ; 
      }
      return;
    }
  }

  getRowClass(row:any){ //rowClass is an ngx-datatable object
    let isViewed = false;
    if(row.hasOwnProperty('documentType')){ //is document
      if(row.hasOwnProperty('metadata')){
        isViewed = row.metadata.viewed == true? true:false ; //viewed is viewed, not viewed is not viewed
      }else{
        isViewed = false ; //no metadata only mean it was never viewed 
      }
    }else{ //not a document, default instant viewed 
      isViewed = true ; 
    }
    if(isViewed){
      return {'viewed':true}
    }else{
      return{'not-viewed':true}
    }
  }

  translateColumnText(){  
    this.columns.forEach( column=>{
      this.translate.get(column.translateKey).subscribe( translatedValue =>{
        column.name = translatedValue ; //changing column of this.columns
      })
    })
  } 

}
