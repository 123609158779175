import { AccountType, User }  from 'common/interfaces/user';
import { CompanyReference } from './common/interfaces/company';

// Using class/interface merging here
// ref -> https://stackoverflow.com/questions/53128744/typescript-automatically-get-interface-properties-in-a-class
export class SignedUser implements Partial<User>{
  private _userId: string;
  private _firstName: string;
  private _lastName: string;
  private _accountType: AccountType;
  private _companies: CompanyReference[];

  constructor(userData: User, private _jwt: string, private _jwtExpirationDate: Date) {
    this.setUserProperties(userData);
   }


  public get JWT(): string {
    if (!this._jwtExpirationDate || new Date() > this._jwtExpirationDate) {
			return null;
		}
		return this._jwt;
  }


  get firstName() {
    return this._firstName
  }

  get lastName() {
    return this._lastName;
  }

  get accountType() {
    return this._accountType;
  }

  get _id() {
    return this._userId;
  }
  get companies() {
    return this._companies;
  }

  private setUserProperties(userData: User) {
    this._userId = userData._id;
    this._firstName = userData.firstName
    this._lastName = userData.lastName;
    this._accountType = userData.accountType;
    this._companies = userData.companies;
  }
}
