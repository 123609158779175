import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MenuController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from 'core/services';
import { ResetPasswordComponent } from 'shared/modals/reset-password/reset-password.page';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {

  signInForm: FormGroup;

  constructor(public authService: AuthService, private router: Router, private menuController: MenuController,
              private modalController: ModalController, private translate: TranslateService,
          ) { }

  ngOnInit() {
    this.signInForm = new FormGroup({
      email: new FormControl(),
      password: new FormControl()
   });

  }

  login(): void {
    if (!this.signInForm.valid) {
      return;
    }

    this.authService.login({ email: this.signInForm.value.email, password: this.signInForm.value.password }).subscribe(() => {
      this.signInForm.reset();
      this.router.navigate(['/dashboard']);
      this.menuController.enable(true);
      this.menuController.swipeGesture(true);
    });
  }

  async onClick() {
    const modal = await this.modalController.create({
      component: ResetPasswordComponent
    });
    return await modal.present();
  }

  async onLangClick(lang: string){
    this.translate.use(lang);
  }
}
