import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { User } from 'common/interfaces/user';
import { UserPrivilege } from 'common/interfaces/privileges';
import { RecordPageRequest } from 'app/shared/common/interfaces/search';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  // needed when working with the users as admin/superadmin
  private _openUser: User;

  constructor(private http: HttpClient) { }

  getUsers(companyId: string, recordPageRequest: RecordPageRequest): Observable<{users:User[]; total: number;}> {
    return this.http.post<{users:User[]; total: number;}>(`user/list`, {companyId, recordPageRequest});
  }
  
  getActiveAccountEmail(token: string): Observable<User['email']> {
    return this.http.get<User['email']>(`user/verifyAccountEmail/${token}`);
  }

  getUserId(email: User['email']): Observable<User['_id']> {
    return this.http.get<User['_id']>(`user/id/${email}`);
  }

  getUser(id: string): Observable<User> {
    return this.http.get<User>(`user/${id}`);
  }

  // POST requests use responseType 'text' as thr server doesn't return JSON. Leaving this option out will causes a client side error
  updateUser(user: User) {// , userPrivileges: UserPrivilege[]) {
    // TODO: add userPrivileges object to POST body
    return this.http.put('user', {user}, {responseType: 'text'});
  }

  createUser(user: User, userPrivileges: UserPrivilege[]) {
    return this.http.post('user', {user, userPrivileges}, {responseType: 'text'});
  }

  deleteUsers(userIds: string[]) {
    return this.http.delete('user', { body: { userIds }, responseType: 'text' });
  }
  
  

  get openUser() {
    return this._openUser;
  }

  set openUser(user: User) {
    this._openUser = user;
  }

}
