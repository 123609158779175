import { Injectable } from '@angular/core';
import { UserPrivilege } from 'common/interfaces/privileges';
import { RecordTypes } from 'common/interfaces/global';
import { BinaryActions } from 'app/shared/common/actions';
import { UserPrivilegesService } from 'app/users/user-privileges.service';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import * as PrivilegeHelpers from 'app/shared/common/modules/privilege-helpers';

@Injectable({
  providedIn: 'root'
})
export class CheckPrivilegeService {


  constructor(private userPrivilegesService: UserPrivilegesService, private authService: AuthService) {

  }

  activeUserConfig: UserPrivilege;

  /**
   *
   * Checks a specific privilege
   *
   * @param recordType record type as a string
   * @param tradingPartnerId trading parter ID
   * @param privilegeName name of the privilege as a string
   *
   * @returns boolean, true if the user has the specified privilege
   */
  // TODO: this might need a null check as to when the user is logged out
  checkPrivilege(
    recordType: keyof RecordTypes<BinaryActions>,
    tradingPartnerId: any,
    privilegeName: keyof typeof BinaryActions
  ): Observable<boolean>;
  /**
   *
   * Checks privilege for all actions for a specific record type and trading partner
   *
   * @param recordType record type as a string
   * @param tradingPartnerId trading parter ID
   *
   * @returns BinaryActions number for specified record type and trading partner
   */
  checkPrivilege(recordType: keyof RecordTypes<BinaryActions>, tradingPartnerId: any): Observable<BinaryActions>;
  /**
   *
   * Checks privilege for all actions and trading partners, for a specific record type
   *
   * @param recordType record type as a string
   *
   * @returns array containing BinaryActions numbers for specified record type
   */
  checkPrivilege(recordType: keyof RecordTypes<BinaryActions>): Observable<{ tradingPartnerId: string; binaryActions: BinaryActions }[]>;
  /**
   *
   * Checks all privileges for all trading partners and all record types
   *
   * @returns array containing BinaryActions numbers for all trading partners and record types
   */
  checkPrivilege(): Observable<{ tradingPartnerId: string; actionPrivileges: RecordTypes<BinaryActions> }[]>;
  checkPrivilege(
    recordType?: keyof RecordTypes<BinaryActions>,
    tradingPartnerId?: any,
    privilegeName?: keyof typeof BinaryActions
  ): Observable<boolean> |
     Observable<BinaryActions> |
     Observable<{ tradingPartnerId: any; binaryActions: BinaryActions }[]> |
     Observable<{ tradingPartnerId: string; actionPrivileges: RecordTypes<BinaryActions> }[]> {
    if (privilegeName) {
      /* overload logic for
      checkPrivilege(
        recordType: keyof RecordTypes<BinaryActions>,
        tradingPartnerId: any,
        privilegeName: keyof typeof BinaryActions
      ) */
      return this.userPrivilegesService.getTradingPartnerPrivileges(this.authService.user.value._id, tradingPartnerId).pipe(
        take(1),
        map(userPrivilege => {
          const recordTypePrivilege: BinaryActions = userPrivilege.actionPrivileges[recordType];
          return PrivilegeHelpers.contains(recordTypePrivilege, privilegeName);
        })
      );
    } else if (tradingPartnerId) {
      /* overload logic for
      checkPrivilege(recordType: keyof RecordTypes<BinaryActions>, tradingPartnerId: any) */
      return this.userPrivilegesService.getTradingPartnerPrivileges(this.authService.user.value._id, tradingPartnerId).pipe(
        take(1),
        map(userPrivilege => {
          return userPrivilege.actionPrivileges[recordType];
        })
      );
    } else if (recordType) {
      /* overload logic for
      checkPrivilege(recordType: keyof RecordTypes<BinaryActions>) */
      return this.userPrivilegesService.getUserPrivileges(this.authService.user.value._id).pipe(
        take(1),
        map(userPrivileges => {
          return userPrivileges.map(userPrivilege => {
            return {
              tradingPartnerId: userPrivilege.tradingPartnerId,
              binaryActions: userPrivilege.actionPrivileges[recordType]
            }
          })
        })
      )
    } else {
      /* overload logic for checkPrivilege() */
      return this.userPrivilegesService.getUserPrivileges(this.authService.user.value._id).pipe(
        take(1),
        map(userPrivileges => {
          return userPrivileges.map(userPrivilege => {
            return {
              tradingPartnerId: userPrivilege.tradingPartnerId,
              actionPrivileges: userPrivilege.actionPrivileges
            }
          })
        })
      )
    }
  }
}
