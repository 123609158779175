import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { DeliveryLocation } from 'common/interfaces/deliveryLocation';
import { RecordPageRequest } from 'app/shared/common/interfaces/search';

@Injectable({
  providedIn: 'root'
})
export class LocationsService {

  constructor(private http: HttpClient) { }

  getLocations(companyId: string, recordPageRequest: RecordPageRequest): Observable<{locations:DeliveryLocation[]; total: number}> {
    return this.http.post<{locations:DeliveryLocation[]; total: number}>('location/list/', {companyId, recordPageRequest} );
  }

  getLocation(id: string): Observable<DeliveryLocation> {
    return this.http.get<DeliveryLocation>(`location/${id}`);
  }

  createLocation(location: DeliveryLocation) {
    return this.http.post('location', {location}, {responseType: 'text'});
  }

  updateLocation(location: DeliveryLocation) {
    return this.http.put('location', {location}, {responseType: 'text'});
  }

  deleteLocations(locationIds: string[]) {
    return this.http.delete('location', {body: {locationIds}, responseType: 'text'});
  }
}
