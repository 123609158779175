import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthService } from 'core/services';
import { UsersService } from 'src/app/users/users.service';
import { User } from 'app/shared/common/interfaces/user';
import { FieldGroup, FormValidationOption } from 'app/shared/interfaces/field-group';
import { Subscription } from 'rxjs';
import { ValidatorService } from 'app/core/services/validator.service';



@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})

export class VerifyComponent implements OnInit {
  fieldGroups: FieldGroup;
  passwordFormGroup: FormGroup;
  user: User;
  userSubscription: Subscription;
  formValidators: FormValidationOption[]; 
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private validatorService : ValidatorService,
    private userService: UsersService
  ) {}

  ngOnInit() {
    this.fieldGroups = this.verifyFormUI();
    this.passwordFormGroup = this.formBuilder.group( 
      {
        email: [{ value: '', disabled: true, }],
        newPassword: [''],
        repeatPassword: [''],
      },
    );
    let ui = {
      uiData: [{ fieldGroups: this.fieldGroups }],
      formValidators: [
        {
          name: 'mustMatch',
          fields: ['newPassword', 'repeatPassword'],
          errorType: 'mustMatch',
          message: 'Passwords do not match',
        },
      ],
    };
    this.formValidators = ui.formValidators;
    this.passwordFormGroup = this.validatorService.setValidatorFromUI(this.passwordFormGroup, ui ); 
  }

  ngOnDestroy() {
    // Perform cleanup actions when the component is destroyed
  }

  ionViewWillEnter() { // TODO: ADD a timeout function to prevent user from attempting to connect to verify page too many times
    const token = this.route.snapshot.queryParams.token; // grab the JSON web token from URL 
    if (!token) {
      return;
    } 
      this.userSubscription = this.userService.getActiveAccountEmail(token).subscribe(
        (userEmail) => {
          this.passwordFormGroup.get('email').setValue(userEmail);  // set the value in the email form builder
        },
        (error) => { // TODO: ADD a timeout function to prevent user from attempting to connect to verify page too many times
          throw new Error('Error fetching user info... Please try again' + error); 
        }
      );
    
  }
  
  ionViewWillLeave() {
    this.userSubscription.unsubscribe();
    this.passwordFormGroup.reset();
  }

  verifyFormUI(): FieldGroup {
    return [
      {
        name: 'verifyFormUI',
        fields: [
          {
            name: 'email',
            label: 'EMAIL',
            type: 'input',
            placeholder: 'EMAIL',
            readonly:true,
            bigScreenSize: 12,
            mediumScreenSize: 12,
            smallScreenSize: 12,
          },
          {
            name: 'newPassword',
            label: 'NEWPASSWORD',
            type: 'input',
            inputType: 'password',
            placeholder: 'NEWPASSWORD',
            clearOnEdit:false,
            toggleIcon: {
              active: 'eye-off-outline',
              inactive: 'eye-outline',
              iconClicked: false,
            },
            bigScreenSize: 12,
            mediumScreenSize: 12,
            smallScreenSize: 12,
            validators: [
              {name:'required',errorType:'required',message:'Please Enter a Password'},
              {name:'minlength',errorType:'minlength', value:'2', message:`Must be at least 2 characters long`},
              ],
          },
          {
            name: 'repeatPassword',
            label: 'REPEATPASSWORD',
            type: 'input',
            inputType: 'password',
            placeholder: 'REPEATPASSWORD',
            clearOnEdit:true,
            toggleIcon: {
              active: 'eye-off-outline',
              inactive: 'eye-outline',
              iconClicked: false,
            },
            bigScreenSize: 12,
            mediumScreenSize: 12,
            smallScreenSize: 12,
            validators: [
              {name:'required',errorType:'required',message:'Please Enter a Password'},
            ],
          },
        ],
      },
    ];
  }

  onSubmit() {  // requires password and token for verification
    if (this.passwordFormGroup.invalid) {
      this.passwordFormGroup.markAllAsTouched();
      return;
    }
    const token = this.route.snapshot.queryParams.token;
    this.authService
      .requestVerification(token, this.passwordFormGroup.value)
      .pipe(first())
      .subscribe({
        next: () => {
          this.router.navigate(['/login']);
        },
        error: (err) => {
          // TODO: We need to have a popup/validation sweep to show all the correct errors
          return err;
        },
      });
  }
}
