import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AlertMatrix } from '../../common/interfaces/global';

@Component({
  selector: 'app-email-alert-action-modal',
  templateUrl: './email-alert-action-modal.page.html',
  styleUrls: ['./email-alert-action-modal.page.scss'],
})
export class EmailAlertActionModalPage implements OnInit {
  @Input() alert: AlertMatrix;

  constructor(public modalController: ModalController) { }

  ngOnInit() {

  }

  onClick() {
    // TODO: display error message to the user
    this.modalController.dismiss();
  }
}
