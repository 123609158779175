import { Injectable } from '@angular/core';
import { CompanyService } from 'app/company/company.service';
import { ChildView, RecordType } from 'app/shared/common/interfaces/global';
import { Invoice } from 'app/shared/common/interfaces/invoice';
import { LineItem } from 'app/shared/common/interfaces/lineItem';
import { Order } from 'app/shared/common/interfaces/order';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
/*This service - set parent documents, create, fill in and return child document*/
export class ChildDocumentService {
  //TODO: work with the new autoFill Service and enrich service
  // output document property value can be undefined
  public parentView: string ;//view reference that start create action
  public parentDocument$ = new BehaviorSubject<Array<RecordType>>([]);//optional, excluded for blank document
  private childDocument : Invoice|Order; //For now only Invoice and *ASN(To be added) can be a child document
  public readonly selectedObjectSubscription = this.parentDocument$.asObservable();

  constructor(private companyService:CompanyService) { }

  public set parentDocument(records:Array<RecordType>) {
    this.parentDocument$.next(records);
  }
  //return a child document for a view's create action
  public getChildDocument (view:ChildView): Invoice|Order{
    this.createChildDocument(view);
    return this.childDocument ; 
  }

  private createChildDocument (childView:ChildView){
    switch(childView){
      case ChildView.ORDER:
        this.createOrder();
        break;
      case ChildView.Invoice:
        this.createInvoice();
        break;
      default : 
        break;
    }
  }

  private createInvoice () {
    this.childDocument = {} as Invoice;
    let lineItems = [] as Array<LineItem>; 
    switch(this.parentView){
      case 'orders':
        this.parentDocument$.value.forEach( order => {  //recordType ignore document property, hence ['key'] to direct access
          this.childDocument.buyerDetails = order['buyerDetails'] ;
          this.childDocument.supplierDetails = order['supplierDetails'];
          this.childDocument.documentNumber = order['documentNumber'];
          lineItems.concat(order['lineItems']);
        })
        this.childDocument.lineItems = this.mergeLineItems(lineItems); 
        break;
      case 'invoices':
        this.childDocument.buyerDetails = {
          id : this.companyService.activeCompany.tradingPartners[0]._id ,
          name : null,
          buyerCode :null,
          address : null
        };
        break;
      default:
        break;
    };
  }

  private createOrder(){
    switch(this.parentView){
      case 'orders'://Blank order when we create order from order, except buyerDetail.id (to fetch UI)
        this.childDocument = {} as Order ; 
        this.childDocument.supplierDetails.id = this.companyService.activeCompany.tradingPartners[0]._id;
        this.childDocument.buyerDetails.id = this.companyService.activeCompany._id ;
        break;
      default:
        break;
    }
  }
  //When creating from multiple document, this function try to merge all line item with same id.
  mergeLineItems( items:Array<LineItem>):Array<LineItem>|null{
    if(items.length>1){
      let resultLineItems = [] as Array<LineItem>;
      items.forEach(item => {
        if(!resultLineItems.some( resultItem=>{ return resultItem.productId===item.productId})){
          resultLineItems.push(item);
        }else{
          let resultItemToMerge = resultLineItems[resultLineItems.findIndex(resultLineItem=>resultLineItem.productId===item.productId)]
          resultItemToMerge.quantity = resultItemToMerge.quantity+item.quantity;
        };
      });
      return resultLineItems ; 
    }

    return items.length==0?null:items;
  }


}