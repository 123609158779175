import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthService } from 'core/services';
import { Company, CompanyReference } from 'common/interfaces/company';
import { AccountType, User } from 'common/interfaces/user';
import { TradingPartner } from 'app/shared/common/interfaces/tradePartner';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  // Company last selected in the sidenav
  private _activeCompany: CompanyReference;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getCompany(id: string): Observable<Company> {
    return this.http.get<Company>(`company/${id}`);
  }

  createCompany(company: Company): Observable<string> {
    return this.http.post('company', {company}, {responseType: 'text'});
  }

  updateCompany(company: Company): Observable<string> {
    return this.http.put('company', {company}, {responseType: 'text'});
  }

  deleteCompany(companyId: string): Observable<string> {
    return this.http.request('DELETE', 'company/delete', {body: {companyIds: [companyId]}, responseType: 'text'});
  }
  
  assignUserToCompany(userId: User['_id'], companyId: CompanyReference['_id'], companyAccountType:AccountType): Observable<Company | User> {
    return this.http.post<User>('company/addUserCompany',{userId, companyId, companyAccountType});
  }

  /**
   * Return a list of companies for the user or the super admin depending on the user account type
   *
   * Providing a company id allows returning only unassigned as trading partner companies
   *
   */
  getCompanies(companyId: string= ''): Observable<CompanyReference[]> {
    if(this.authService.user.value) {
      if (this.authService.user.value.accountType === AccountType.superAdmin) {
        return this.http.get<CompanyReference[]>(`company/list/${companyId}`);
      }
    }
    return this.http.get<CompanyReference[]>(`company/userlist/${companyId}`);
  }

  getTradingPartners(companyId: string = ''): Observable<TradingPartner[]> {
    // TODO this will return all companies. It should return all trading partners of active company which active user has privilege for
    companyId = '631b0b8c1f9f0c0007b8b517'; // TEMPORARY!!! must get active company ID

    return this.http.get<TradingPartner[]>(`company/tradingpartners/list/${companyId}`); // TEMPORARY
  }


  get activeCompany(): CompanyReference {
    return this._activeCompany;
  }

  set activeCompany(company: CompanyReference) {
    this._activeCompany = company;
  }

}
