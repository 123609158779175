import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from 'app/core/services';

@Injectable({
  providedIn: 'root',
})
export class IsInvited implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {

    const invitationCode = route.queryParamMap.get('token');
    if (!invitationCode) { // check condition: if token doesnt exist in url, re-direct to login page
      this.router.navigate(['/login']);
      return false;
    }

    try {
      const token = await this.authService.verifyToken(invitationCode).toPromise();
      if (token.status === 200) { // check condition: if token is valid, grant access to verify page 
        return true;
      } else {         // deny access for invalid token
        this.router.navigate(['/login']);
        return false;
      }
    } catch (error) {  // deny access for any other errors
      this.router.navigate(['/login']);
      return false;
    }
  }
}
