// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-select {
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid #E0E0E0; /* Border color */
  border-radius: 4px; /* Optional: rounded corners */
}

.custom-list {
  margin-bottom: 10px;
  border: 1px solid #E0E0E0; /* Border color */
  border-radius: 4px; /* Optional: rounded corners */
}

.custom-item-no-border {
  border-bottom: none !important; /* Remove the bottom border */
  --border-color: transparent; /* Ensure there's no border color */
}`, "",{"version":3,"sources":["webpack://./src/app/shared/modals/add-products-modal/add-products-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,WAAA;EACA,yBAAA,EAAA,iBAAA;EACA,kBAAA,EAAA,8BAAA;AACJ;;AAGE;EACE,mBAAA;EACA,yBAAA,EAAA,iBAAA;EACA,kBAAA,EAAA,8BAAA;AAAJ;;AAGE;EACE,8BAAA,EAAA,6BAAA;EACA,2BAAA,EAAA,mCAAA;AAAJ","sourcesContent":[".custom-select {\n    margin-bottom: 10px;\n    width: 100%;\n    border:1px solid #E0E0E0; /* Border color */\n    border-radius: 4px; /* Optional: rounded corners */\n  }\n\n\n  .custom-list {\n    margin-bottom: 10px;\n    border:1px solid #E0E0E0; /* Border color */\n    border-radius: 4px; /* Optional: rounded corners */\n\n  }\n  .custom-item-no-border {\n    border-bottom: none !important; /* Remove the bottom border */\n    --border-color: transparent; /* Ensure there's no border color */\n  }\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
