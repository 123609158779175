import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { LineItem } from 'app/shared/common/interfaces/lineItem';
import { IonContent } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class FieldContainerService {
  public ionContentHtml = new BehaviorSubject<number>(0);
  private ionContent : IonContent;
  public lineItemSubject = new BehaviorSubject<Partial<LineItem>[]>(null);
  public getLineItem(): BehaviorSubject<Partial<LineItem>[]> {
    return this.lineItemSubject;
  }
  public set lineItem(x : Partial<LineItem>[]) {
    this.lineItemSubject.next(x);
  }

  setContent(contentHtml: number) {
    this.ionContentHtml.next(contentHtml);
  }

  setIonContent(content: IonContent) {
    this.ionContent = content;
  }

  getIonContent() {
    return this.ionContent;
  }
  
  constructor() { }

}