import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LineItemTableService {

  lineItems: any;

  constructor() { }

  setLineItems(lineItems: any) {
    this.lineItems = lineItems;
  }

  getLineItems() {
    return this.lineItems;
  }
}