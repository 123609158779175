import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UserPrivilege } from 'common/interfaces/privileges';

@Injectable({
  providedIn: 'any',
})
export class UserPrivilegesService {
  constructor(private http: HttpClient) {}

  getUserPrivileges(userId: string): Observable<UserPrivilege[]> {
    return this.http.get<UserPrivilege[]>(`user-privilege/get/${userId}`);
  }

  getTradingPartnerPrivileges(userId: string, tradingPartnerId: string): Observable<UserPrivilege> {
    return this.http.get<UserPrivilege>(`user-privilege/get/${userId}/${tradingPartnerId}`);
  }
}
